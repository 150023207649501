import React, { useEffect, useContext } from 'react';
import { Text, Row, Div, Image } from 'atomize';
import { ShopContext } from '../context/shopContext';
import {Helmet} from 'react-helmet'

const AboutPage = () => {
    const { resetContext } = useContext(ShopContext);
    useEffect(() => {
        resetContext();
        return () => {
        };
    }, [resetContext])

    return (
        <Div
            justify="center"
        >
            <Helmet>
                <title>About Us</title>
                <meta name="About SK Swim- Boutique Women's Swimwear" content="Inspired by the tropics. Dreamt up in Louisiana. Sk swim is a dream come true for a southern girl with a passion for life and a desire to give others the confidence to look good and feel even better. SK SWIM was designed and visualized in Louisiana by Sarah Kate Reynolds. She launched SK SWIM in 2021 with a vision of embodying her sunny perspective in a summer girl’s go-to outfit, the perfect bikini." />
            </Helmet>
            <Div
                style={{paddingTop:"1rem"}}
            >
                <Row
                    justify="center"
                    p={{b:"1rem"}}
                >
                    <Text
                        textColor="black"
                        textWeight="700"
                        textSize={{xs: "display1", sm:"display2", md:"display3"}}
                        style={{
                            fontStyle: 'italic',
                        }}
                    >
                        a b o u t
                    </Text>
                    <Row
                        justify="center"
                        m={{t:{xs:"1.25rem", sm:"1.4rem", md:"2rem"}}}
                        style={{
                            paddingLeft: '1rem',
                            paddingRight: '1rem'
                        }}
                    >
                        <img src={require('../images/star.png')} alt="star" width="25%" height="35%"/>
                    </Row>
                    <Text
                        textColor="black"
                        textWeight="700"
                        textSize={{xs: "display1", sm:"display2", md:"display3"}}
                        style={{
                            fontStyle: 'italic',
                        }}
                    >
                        u s
                    </Text>
                </Row>
            </Div>
            <Div
                style={{
                    zIndex:"400",
                }}
            >
                <Row
                    justify="center"
                >
                    <Image
                        src={require('../images/about.jpg')}
                        rounded="circle"
                        w="18rem"
                    />
                </Row>
                <Row
                    justify="center"
                    w="100%"
                >       
                    <Text
                        textColor="black"
                        textWeight="700"
                        textSize="heading"
                        m={{t:".5rem", b:"3rem"}}
                        style={{
                            fontStyle: 'italic',
                        }}
                    >
                        INSPIRED BY THE TROPICS
                    </Text>
                </Row>
                <Div
                    bgImg={require('../images/abback.jpg')}
                    p={{b:"8rem", t:"6rem"}}
                    bgSize="cover"
                    bgPos="center"
                >
                    <Div
                        bg="#FFF3F6E5"
                        m={{t:"2rem", r:"2rem", l:"2rem"}}
                    >
                        <Text
                            textWeight="600"
                            textSize="title"
                            p={{t:"2rem"}}
                            style={{
                                fontStyle: 'italic',
                                display: 'block',
                                textAlign: 'center',
                            }}
                            textColor="#EE3F84"
                        >
                            What is SK Swim?
                        </Text>
                        <Text
                            textWeight="600"
                            textSize="subheader"
                            style={{
                                paddingTop:"1rem",
                                paddingLeft:"6%",
                                paddingRight:"6%",
                                paddingBottom:"2rem",
                                display: 'block',
                                textAlign: 'center'
                            }}
                        >
                            Sk swim is a dream come true for a southern girl with a passion for life and a desire to give others the confidence to look good and feel even better. SK SWIM was designed and visualized in Louisiana by Sarah Kate Reynolds. She launched SK SWIM in 2021 with a vision of embodying her sunny perspective in a summer girl’s go-to outfit, the perfect bikini.
                        </Text>
                    </Div>
                    <Div
                        bg="#FFF3F6E5"
                        m={{t:"2rem", r:"2rem", l:"2rem"}}
                    >
                        <Text
                            textWeight="600"
                            textSize="title"
                            p={{t:"2rem"}}
                            style={{
                                fontStyle: 'italic',
                                display: 'block',
                                textAlign: 'center',
                            }}
                            textColor="#EE3F84"
                        >
                            What can you expect?
                        </Text>
                        <Text
                            textWeight="600"
                            textSize="subheader"
                            style={{
                                paddingTop:"1rem",
                                paddingLeft:"6%",
                                paddingRight:"6%",
                                paddingBottom:"2rem",
                                display: 'block',
                                textAlign: 'center'
                            }}
                        >
                            Vibrant colors, trendy shapes, and sexy tropical vibes! The look and quality of high fashion with a price you’ll love. Feeling powerful and bold while rocking your favorite ‘kini.
                        </Text>
                    </Div>
                    <Div
                        bg="#FFF3F6E5"
                        m={{t:"2rem", r:"2rem", l:"2rem"}}
                    >
                        <Text
                            textWeight="600"
                            textSize="title"
                            p={{t:"2rem"}}
                            style={{
                                fontStyle: 'italic',
                                display: 'block',
                                textAlign: 'center',
                            }}
                            textColor="#EE3F84"
                        >
                            Why choose SK Swim?
                        </Text>
                        <Text
                            textWeight="600"
                            textSize="subheader"
                            style={{
                                paddingTop:"1rem",
                                paddingLeft:"6%",
                                paddingRight:"6%",
                                paddingBottom:"2rem",
                                display: 'block',
                                textAlign: 'center'
                            }}
                        >
                            Because every body is a bikini body with the right attitude. Wear SK SWIM with confidence and show the world YOU are in charge.
                        </Text>
                    </Div>
                </Div>
            </Div>
        </Div>
    )
}

export default AboutPage;