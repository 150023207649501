import React, { useEffect, useContext } from 'react';
import { Container, Text, Row, Div, Col } from 'atomize';
import { ShopContext } from '../context/shopContext';
import {Helmet} from 'react-helmet'

const PrivacyPolicyPage = () => {
    const { resetContext } = useContext(ShopContext);
    useEffect(() => {
        resetContext();
        return () => {
        };
    }, [resetContext])
    return (
        <div>
            <Helmet>
                <title>Privacy Policy</title>
            </Helmet>
            <Container>
                <Row
                    style={{paddingTop:'1rem'}}
                    justify="center"
                >
                    <Text
                        textColor="black"
                        textWeight="700"
                        textSize={{xs: "display1", sm:"display2", md:"display3"}}
                        style={{
                            fontStyle: 'italic',
                        }}
                    >
                        p r i v a c y
                    </Text>
                    <Row
                        justify="center"
                        m={{t:{xs:"1.25rem", sm:"1.4rem", md:"2rem"}}}
                        style={{
                            paddingLeft: '1rem',
                            paddingRight: '1rem'
                        }}
                    >
                        <img src={require('../images/star.png')} alt="star" width="25%" height="35%"/>
                    </Row>
                    <Text
                        textColor="black"
                        textWeight="700"
                        textSize={{xs: "display1", sm:"display2", md:"display3"}}
                        style={{
                            fontStyle: 'italic',
                        }}
                    >
                        p o l i c y
                    </Text>
                </Row>
            </Container>
            <Div
                h="100%"
                bgImg={require('../images/sk.jpeg')}
                bgPos="center center"
                bgSize="cover"
                style={{marginTop:"2rem", marginBottom:'-2rem', paddingBottom:"8rem", imageRendering:"smooth"}}
                justify="center"
            >
                <Container
                    p={{t:"6rem", l:{xs:"2rem", sm:"5rem"}, r:{xs:"2rem", sm:"5rem"}}}
                >
                    <Col
                        bg="#FFF3F6E5"
                    >
                        <Row
                            justify="center"
                        >
                            <Text
                                textWeight="600"
                                textSize="title"
                                style={{
                                    paddingTop:"4rem",
                                    fontStyle: 'italic',
                                    display: 'block',
                                    textAlign: 'center'
                                }}
                                textColor="#EE3F84"
                            >
                                Privacy Policy
                            </Text>
                        </Row>
                        <Row
                            justify="center"
                        >
                            <Text
                                textWeight="600"
                                textSize="subheader"
                                style={{
                                    paddingTop:"1rem",
                                    paddingLeft:"6%",
                                    paddingRight:"6%",
                                    display: 'block',
                                    textAlign: 'center'
                                }}
                            >
                                At shopskswim.com, accessible from http://www.shopskswim.com/, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by shopskswim.com and how we use it.
                            </Text>
                        </Row>
                        <Row
                            justify="center"
                        >
                            <Text
                                textWeight="600"
                                textSize="subheader"
                                style={{
                                    paddingTop:"1rem",
                                    paddingLeft:"6%",
                                    paddingRight:"6%",
                                    display: 'block',
                                    textAlign: 'center'
                                }}
                            >
                                If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.
                            </Text>
                        </Row>
                        <Row
                            justify="center"
                        >
                            <Text
                                textWeight="600"
                                textSize="subheader"
                                style={{
                                    paddingTop:"1rem",
                                    paddingLeft:"6%",
                                    paddingRight:"6%",
                                    display: 'block',
                                    textAlign: 'center'
                                }}
                            >
                                This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in shopskswim.com. This policy is not applicable to any information collected offline or via channels other than this website.
                            </Text>
                        </Row>
                        <Row
                            justify="center"
                        >
                            <Text
                                textWeight="600"
                                textSize="title"
                                style={{
                                    paddingTop:"2rem",
                                    fontStyle: 'italic',
                                    display: 'block',
                                    textAlign: 'center'
                                }}
                                textColor="#EE3F84"
                            >
                                Consent
                            </Text>
                        </Row>
                        <Row
                            justify="center"
                        >
                            <Text
                                textWeight="600"
                                textSize="subheader"
                                style={{
                                    paddingTop:"1rem",
                                    paddingLeft:"6%",
                                    paddingRight:"6%",
                                    display: 'block',
                                    textAlign: 'center'
                                }}
                            >
                                By using our website, you hereby consent to our Privacy Policy and agree to its terms.
                            </Text>
                        </Row>
                        <Row
                            justify="center"
                        >
                            <Text
                                textWeight="600"
                                textSize="title"
                                style={{
                                    paddingTop:"2rem",
                                    fontStyle: 'italic',
                                    display: 'block',
                                    textAlign: 'center'
                                }}
                                textColor="#EE3F84"
                            >
                                Information We Collect
                            </Text>
                        </Row>
                        <Row
                            justify="center"
                        >
                            <Text
                                textWeight="600"
                                textSize="subheader"
                                style={{
                                    paddingTop:"1rem",
                                    paddingLeft:"6%",
                                    paddingRight:"6%",
                                    display: 'block',
                                    textAlign: 'center'
                                }}
                            >
                                The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.
                            </Text>
                        </Row>
                        <Row
                            justify="center"
                        >
                            <Text
                                textWeight="600"
                                textSize="subheader"
                                style={{
                                    paddingTop:"1rem",
                                    paddingLeft:"6%",
                                    paddingRight:"6%",
                                    display: 'block',
                                    textAlign: 'center'
                                }}
                            >
                                If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.
                            </Text>
                        </Row>
                        <Row
                            justify="center"
                        >
                            <Text
                                textWeight="600"
                                textSize="subheader"
                                style={{
                                    paddingTop:"1rem",
                                    paddingLeft:"6%",
                                    paddingRight:"6%",
                                    display: 'block',
                                    textAlign: 'center'
                                }}
                            >
                                When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number.
                            </Text>
                        </Row>
                        <Row
                            justify="center"
                        >
                            <Text
                                textWeight="600"
                                textSize="title"
                                style={{
                                    paddingTop:"2rem",
                                    fontStyle: 'italic',
                                    display: 'block',
                                    textAlign: 'center'
                                }}
                                textColor="#EE3F84"
                            >
                                How We Use Your Information
                            </Text>
                        </Row>
                        <Row
                            justify="center"
                        >
                            <Text
                                textWeight="600"
                                textSize="subheader"
                                style={{
                                    paddingTop:"1rem",
                                    paddingLeft:"6%",
                                    paddingRight:"6%",
                                    display: 'block',
                                    textAlign: 'center'
                                }}
                            >
                                We use the information we collect in various ways, including to: (1) Provide, operate, and maintain our website; (2) Improve, personalize, and expand our website; (3) Understand and analyze how you use our website; (4) Develop new products, services, features, and functionality; (5) Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes; (6) Send you emails; (7) Find and prevent fraud;
                            </Text>
                        </Row>
                        <Row
                            justify="center"
                        >
                            <Text
                                textWeight="600"
                                textSize="title"
                                style={{
                                    paddingTop:"2rem",
                                    fontStyle: 'italic',
                                    display: 'block',
                                    textAlign: 'center'
                                }}
                                textColor="#EE3F84"
                            >
                                Log Files
                            </Text>
                        </Row>
                        <Row
                            justify="center"
                        >
                            <Text
                                textWeight="600"
                                textSize="subheader"
                                style={{
                                    paddingTop:"1rem",
                                    paddingLeft:"6%",
                                    paddingRight:"6%",
                                    display: 'block',
                                    textAlign: 'center'
                                }}
                            >
                                shopskswim.com follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.
                            </Text>
                        </Row>
                        <Row
                            justify="center"
                        >
                            <Text
                                textWeight="600"
                                textSize="title"
                                style={{
                                    paddingTop:"2rem",
                                    fontStyle: 'italic',
                                    display: 'block',
                                    textAlign: 'center'
                                }}
                                textColor="#EE3F84"
                            >
                                Cookies and Web Beacons
                            </Text>
                        </Row>
                        <Row
                            justify="center"
                        >
                            <Text
                                textWeight="600"
                                textSize="subheader"
                                style={{
                                    paddingTop:"1rem",
                                    paddingLeft:"6%",
                                    paddingRight:"6%",
                                    display: 'block',
                                    textAlign: 'center'
                                }}
                            >
                                Like any other website, shopskswim.com uses 'cookies'. These cookies are used to store information including visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by customizing our web page content based on visitors' browser type and/or other information.
                            </Text>
                        </Row>
                        <Row
                            justify="center"
                        >
                            <Text
                                textWeight="600"
                                textSize="subheader"
                                style={{
                                    paddingTop:"1rem",
                                    paddingLeft:"6%",
                                    paddingRight:"6%",
                                    display: 'block',
                                    textAlign: 'center'
                                }}
                            >
                                For more general information on cookies, please read <a style={{color:"#EE3F84"}} href="https://www.termsofservicegenerator.net/" target="_blank">"What are Cookies"</a>.
                            </Text>
                        </Row>
                        <Row
                            justify="center"
                        >
                            <Text
                                textWeight="600"
                                textSize="title"
                                style={{
                                    paddingTop:"2rem",
                                    fontStyle: 'italic',
                                    display: 'block',
                                    textAlign: 'center'
                                }}
                                textColor="#EE3F84"
                            >
                                Advertising Partners Privacy Policies
                            </Text>
                        </Row>
                        <Row
                            justify="center"
                        >
                            <Text
                                textWeight="600"
                                textSize="subheader"
                                style={{
                                    paddingTop:"1rem",
                                    paddingLeft:"6%",
                                    paddingRight:"6%",
                                    display: 'block',
                                    textAlign: 'center'
                                }}
                            >
                                You may consult this list to find the Privacy Policy for each of the advertising partners of shopskswim.com.
                            </Text>
                        </Row>
                        <Row
                            justify="center"
                        >
                            <Text
                                textWeight="600"
                                textSize="subheader"
                                style={{
                                    paddingTop:"1rem",
                                    paddingLeft:"6%",
                                    paddingRight:"6%",
                                    display: 'block',
                                    textAlign: 'center'
                                }}
                            >
                                Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on shopskswim.com, which are sent directly to users' browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.
                            </Text>
                        </Row>
                        <Row
                            justify="center"
                        >
                            <Text
                                textWeight="600"
                                textSize="subheader"
                                style={{
                                    paddingTop:"1rem",
                                    paddingLeft:"6%",
                                    paddingRight:"6%",
                                    display: 'block',
                                    textAlign: 'center'
                                }}
                            >
                                Note that shopskswim.com has no access to or control over these cookies that are used by third-party advertisers.
                            </Text>
                        </Row>
                        <Row
                            justify="center"
                        >
                            <Text
                                textWeight="600"
                                textSize="title"
                                style={{
                                    paddingTop:"2rem",
                                    fontStyle: 'italic',
                                    display: 'block',
                                    textAlign: 'center'
                                }}
                                textColor="#EE3F84"
                            >
                                Third Party Privacy Policies
                            </Text>
                        </Row>
                        <Row
                            justify="center"
                        >
                            <Text
                                textWeight="600"
                                textSize="subheader"
                                style={{
                                    paddingTop:"1rem",
                                    paddingLeft:"6%",
                                    paddingRight:"6%",
                                    display: 'block',
                                    textAlign: 'center'
                                }}
                            >
                                shopskswim.com's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options.
                            </Text>
                        </Row>
                        <Row
                            justify="center"
                        >
                            <Text
                                textWeight="600"
                                textSize="subheader"
                                style={{
                                    paddingTop:"1rem",
                                    paddingLeft:"6%",
                                    paddingRight:"6%",
                                    display: 'block',
                                    textAlign: 'center'
                                }}
                            >
                                You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites.
                            </Text>
                        </Row>
                        <Row
                            justify="center"
                        >
                            <Text
                                textWeight="600"
                                textSize="title"
                                style={{
                                    paddingTop:"2rem",
                                    fontStyle: 'italic',
                                    display: 'block',
                                    textAlign: 'center'
                                }}
                                textColor="#EE3F84"
                            >
                                CCPA Privacy Rights (Do Not Sell My Personal Information)
                            </Text>
                        </Row>
                        <Row
                            justify="center"
                        >
                            <Text
                                textWeight="600"
                                textSize="subheader"
                                style={{
                                    paddingTop:"1rem",
                                    paddingLeft:"6%",
                                    paddingRight:"6%",
                                    display: 'block',
                                    textAlign: 'center'
                                }}
                            >
                                Under the CCPA, among other rights, California consumers have the right to:
                            </Text>
                        </Row>
                        <Row
                            justify="center"
                        >
                            <Text
                                textWeight="600"
                                textSize="subheader"
                                style={{
                                    paddingTop:"1rem",
                                    paddingLeft:"6%",
                                    paddingRight:"6%",
                                    display: 'block',
                                    textAlign: 'center'
                                }}
                            >
                                Request that a business that collects a consumer's personal data disclose the categories and specific pieces of personal data that a business has collected about consumers.
                            </Text>
                        </Row>
                        <Row
                            justify="center"
                        >
                            <Text
                                textWeight="600"
                                textSize="subheader"
                                style={{
                                    paddingTop:"1rem",
                                    paddingLeft:"6%",
                                    paddingRight:"6%",
                                    display: 'block',
                                    textAlign: 'center'
                                }}
                            >
                                Request that a business delete any personal data about the consumer that a business has collected.
                            </Text>
                        </Row>
                        <Row
                            justify="center"
                        >
                            <Text
                                textWeight="600"
                                textSize="subheader"
                                style={{
                                    paddingTop:"1rem",
                                    paddingLeft:"6%",
                                    paddingRight:"6%",
                                    display: 'block',
                                    textAlign: 'center'
                                }}
                            >
                                Request that a business that sells a consumer's personal data, not sell the consumer's personal data.
                            </Text>
                        </Row>
                        <Row
                            justify="center"
                        >
                            <Text
                                textWeight="600"
                                textSize="subheader"
                                style={{
                                    paddingTop:"1rem",
                                    paddingLeft:"6%",
                                    paddingRight:"6%",
                                    display: 'block',
                                    textAlign: 'center'
                                }}
                            >
                                If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.
                            </Text>
                        </Row>
                        <Row
                            justify="center"
                        >
                            <Text
                                textWeight="600"
                                textSize="title"
                                style={{
                                    paddingTop:"2rem",
                                    fontStyle: 'italic',
                                    display: 'block',
                                    textAlign: 'center'
                                }}
                                textColor="#EE3F84"
                            >
                                GDPR Data Protection Rights
                            </Text>
                        </Row>
                        <Row
                            justify="center"
                        >
                            <Text
                                textWeight="600"
                                textSize="subheader"
                                style={{
                                    paddingTop:"1rem",
                                    paddingLeft:"6%",
                                    paddingRight:"6%",
                                    display: 'block',
                                    textAlign: 'center'
                                }}
                            >
                                We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:
                            </Text>
                        </Row>
                        <Row
                            justify="center"
                        >
                            <Text
                                textWeight="600"
                                textSize="subheader"
                                style={{
                                    paddingTop:"1rem",
                                    paddingLeft:"6%",
                                    paddingRight:"6%",
                                    display: 'block',
                                    textAlign: 'center'
                                }}
                            >
                                The right to access – You have the right to request copies of your personal data. We may charge you a small fee for this service.
                            </Text>
                        </Row>
                        <Row
                            justify="center"
                        >
                            <Text
                                textWeight="600"
                                textSize="subheader"
                                style={{
                                    paddingTop:"1rem",
                                    paddingLeft:"6%",
                                    paddingRight:"6%",
                                    display: 'block',
                                    textAlign: 'center'
                                }}
                            >
                                The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.
                            </Text>
                        </Row>
                        <Row
                            justify="center"
                        >
                            <Text
                                textWeight="600"
                                textSize="subheader"
                                style={{
                                    paddingTop:"1rem",
                                    paddingLeft:"6%",
                                    paddingRight:"6%",
                                    display: 'block',
                                    textAlign: 'center'
                                }}
                            >
                                The right to erasure – You have the right to request that we erase your personal data, under certain conditions.
                            </Text>
                        </Row>
                        <Row
                            justify="center"
                        >
                            <Text
                                textWeight="600"
                                textSize="subheader"
                                style={{
                                    paddingTop:"1rem",
                                    paddingLeft:"6%",
                                    paddingRight:"6%",
                                    display: 'block',
                                    textAlign: 'center'
                                }}
                            >
                                The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.
                            </Text>
                        </Row>
                        <Row
                            justify="center"
                        >
                            <Text
                                textWeight="600"
                                textSize="subheader"
                                style={{
                                    paddingTop:"1rem",
                                    paddingLeft:"6%",
                                    paddingRight:"6%",
                                    display: 'block',
                                    textAlign: 'center'
                                }}
                            >
                                The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.
                            </Text>
                        </Row>
                        <Row
                            justify="center"
                        >
                            <Text
                                textWeight="600"
                                textSize="subheader"
                                style={{
                                    paddingTop:"1rem",
                                    paddingLeft:"6%",
                                    paddingRight:"6%",
                                    display: 'block',
                                    textAlign: 'center'
                                }}
                            >
                                The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.
                            </Text>
                        </Row>
                        <Row
                            justify="center"
                        >
                            <Text
                                textWeight="600"
                                textSize="subheader"
                                style={{
                                    paddingTop:"1rem",
                                    paddingLeft:"6%",
                                    paddingRight:"6%",
                                    display: 'block',
                                    textAlign: 'center'
                                }}
                            >
                                If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.
                            </Text>
                        </Row>
                        <Row
                            justify="center"
                        >
                            <Text
                                textWeight="600"
                                textSize="title"
                                style={{
                                    paddingTop:"2rem",
                                    fontStyle: 'italic',
                                    display: 'block',
                                    textAlign: 'center'
                                }}
                                textColor="#EE3F84"
                            >
                                Children's Information
                            </Text>
                        </Row>
                        <Row
                            justify="center"
                        >
                            <Text
                                textWeight="600"
                                textSize="subheader"
                                style={{
                                    paddingTop:"1rem",
                                    paddingLeft:"6%",
                                    paddingRight:"6%",
                                    display: 'block',
                                    textAlign: 'center'
                                }}
                            >
                                Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.
                            </Text>
                        </Row>
                        <Row
                            justify="center"
                        >
                            <Text
                                textWeight="600"
                                textSize="subheader"
                                style={{
                                    paddingTop:"1rem",
                                    paddingLeft:"6%",
                                    paddingRight:"6%",
                                    display: 'block',
                                    textAlign: 'center'
                                }}
                            >
                                shopskswim.com does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.
                            </Text>
                        </Row>
                        <Row
                            justify="center"
                        >
                            <Text
                                textWeight="600"
                                textSize="caption"
                                style={{
                                    paddingTop:"2rem",
                                    paddingLeft:"6%",
                                    paddingRight:"6%",
                                    paddingBottom:"4rem",
                                    display: 'block',
                                    textAlign: 'center'
                                }}
                            >
                                Last updated 11/22/2022.
                            </Text>
                        </Row>
                    </Col>
                </Container>
            </Div>
        </div>
    )
}

export default PrivacyPolicyPage;