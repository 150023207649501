import React, { useState, useEffect, useContext } from 'react';
import { ShopContext } from '../context/shopContext';
import { Container, Row, Text, Div, Input, Button, Col } from 'atomize';
import emailjs from 'emailjs-com'
import {Helmet} from 'react-helmet'

const ReturnPage = () => {
    const { resetContext } = useContext(ShopContext);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [emailSent, setEmailSent] = useState(false);

    const submit = () => {
        const serviceId = 'service_9w1l4xk';
        const templateId = 'template_pn2wzpq';
        const userId = 'cb6yeJTXPsKDpEUPR';
        const templateParams = {
            name,
            email,
            subject,
            message
        };

        emailjs.send(serviceId, templateId, templateParams, userId)
            .then(response => console.log(response))
            .then(error => console.log(error));
        setName('');
        setEmail('');
        setMessage('');
        setSubject('')
        confirmEmail();
    }

    const delay = ms => new Promise(res => setTimeout(res, ms));

    const confirmEmail = async () => {
        setEmailSent(true);
        await delay(3000);
        setEmailSent(false);
    };

    useEffect(() => {
        resetContext();
        return () => {
        };
    }, [resetContext])


    return (
        <div>
            <Helmet>
                <title>Return Request</title>
                <meta name="Return Request" content="SK Swim accepts unworn, unwashed suits with tags and hygiene liners attached within 30 days of delivery"/>
            </Helmet>
            <Container
                style={{
                    marginBottom:'2rem'
                }}
            >
                <Row
                    justify="center"
                    style={{paddingTop:'1rem'}}
                >
                    <Text
                        textColor="black"
                        textWeight="700"
                        textSize={{xs: "display1", sm:"display2", md:"display3"}}
                        style={{
                            fontStyle: 'italic',
                        }}
                    >
                        r e t u r n
                    </Text>
                    <Row
                        justify="center"
                        m={{t:{xs:"1.25rem", sm:"1.4rem", md:"2rem"}}}
                        style={{
                            paddingLeft: '1rem',
                            paddingRight: '1rem'
                        }}
                    >
                        <img src={require('../images/star.png')} alt="star" width="25%" height="35%"/>
                    </Row>
                    <Text
                        textColor="black"
                        textWeight="700"
                        textSize={{xs: "display1", sm:"display2", md:"display3"}}
                        style={{
                            fontStyle: 'italic',
                        }}
                    >
                        p o l i c y
                    </Text>
                </Row>
                <Row
                    justify="center"
                >
                    <Text
                        textWeight="600"
                        textSize="caption"
                        p={{t:"1rem", l:"6%", r:"6%"}}
                        style={{
                            textColor:'black',
                            textAlign:"center"
                        }}
                        w="35%"
                    >
                        *SK Swim accepts unworn, unwashed suits with tags and hygiene liners attached within 30 days of delivery
                    </Text>
                </Row>
            </Container>
            <Col>
            <Div
                w="100%"
                h="100%"
                bg="#FFF3F6"
                justify="center"
            >
                <Row
                    bg="#FFF3F6E5"
                    justify="center"
                >
                    <Col
                        size="2"
                        justify="center"
                        d={{xs:"none", sm:"none", md:"block"}}
                    >
                        <img 
                            style={{
                                height:"23rem",
                                width:"6rem",
                                imageRendering:'smooth'
                            }}
                            alt="1"
                            src={require('../images/1.png')}
                        />
                    </Col>
                    <Col
                        size="8"
                    >
                        <Text
                            textWeight="600"
                            textSize="title"
                            style={{
                                paddingTop:"5rem",
                                fontStyle: 'italic',
                                display: 'block',
                                textAlign: 'center'
                            }}
                            textColor="#EE3F84"
                        >
                            Use the form below to request your return and/or refund.
                        </Text>
                        <Text
                            textWeight="600"
                            textSize="subheader"
                            p={{t:"1rem", l:"6%", r:"6%", b:"5rem"}}
                            style={{
                                display: 'block',
                                textAlign: 'center'
                            }}
                        >
                            The staff at SK SWIM want you to have an awesome experience and will work tirelessly to process your refund request. Simply answer a few questions on the form below and let us get to work finding you a solution. Please note items are only eligible for return within 14 days of delivery.
                        </Text>
                    </Col>
                </Row>
            </Div>
            <Div
                w="100%"
                bg="#FFF3F6"
                justify="center"
            >
                <Row
                    bg="white"
                    justify="center"
                >       
                    <Col
                        size="8"
                    >
                        <Text
                            textWeight="600"
                            textSize="title"
                            style={{
                                paddingTop:"5rem",
                                fontStyle: 'italic',
                                display: 'block',
                                textAlign: 'center'
                            }}
                            textColor="#EE3F84"
                        >
                            Your returns and refunds are processed within 72 hours.
                        </Text>
                        <Text
                            textWeight="600"
                            textSize="subheader"
                            p={{t:"1rem", l:"6%", r:"6%", b:"5rem"}}
                            style={{
                                display: 'block',
                                textAlign: 'center'
                            }}
                        >
                            Once we process your request we will answer your return or refund request as soon as we can. Sometimes even the same day, but always within 72 hours. Note we can not take returns on internationally shipped items or sale items at this time, however, we can sometimes give store credit.
                        </Text>
                    </Col>
                    <Col
                        size="2"
                        justify="center"
                        d={{xs:"none", sm:"none", md:"block"}}
                    >
                        <img 
                            src={require('../images/2.png')}
                            alt="2"
                            style={{
                                height:"23rem",
                                width:"10rem",
                                imageRendering:'smooth'
                            }}
                        />
                    </Col>
                </Row>
            </Div>
            <Div
                w="100%"
                bg="#FFF3F6"
                justify="center"
            >
                <Row
                    bg="#FFF3F6E5"
                    justify="center"
                >
                    <Col
                        size="2"
                        justify="center"
                        d={{xs:"none", sm:"none", md:"block"}}
                    >
                        <img 
                            src={require('../images/3.png')}
                            style={{
                                height:"23rem",
                                width:"10rem",
                                imageRendering:'smooth'
                            }}
                            alt="3"
                        />
                    </Col>
                    <Col
                        size="8"
                    >
                        <Text
                            textWeight="600"
                            textSize="title"
                            style={{
                                paddingTop:"5rem",
                                fontStyle: 'italic',
                                display: 'block',
                                textAlign: 'center'
                            }}
                            textColor="#EE3F84"
                        >
                            Ship the product back to us and/or shop with your credit.
                        </Text>
                        <Text
                            textWeight="600"
                            textSize="subheader"
                            p={{t:"1rem", l:"6%", r:"6%", b:"5rem"}}
                            style={{
                                display: 'block',
                                textAlign: 'center'
                            }}
                        >
                            Once your return is approved, you will receive a digital copy of the return shipping label if applicable to ship your item back. While that is taking place we will process your refund, issue your store credit or ship your new items. If at any time you have a question, email us at support@shopskswim.com.
                        </Text>
                    </Col>
                </Row>
            </Div>
            </Col>
            <Div
                h="100%"
                bgImg={require('../images/return.png')}
                bgPos="center center"
                bgSize="cover"
                justify="center"
                style={{
                    imageRendering:'smooth'
                }}
            >
                <Container
                    p={{t:"6rem", l:{xs:"2rem", sm:"3rem", md:"7rem"}, r:{xs:"2rem", sm:"3rem", md:"7rem"}, b:"6rem"}}
                >
                    <Row
                        bg="#FFF3F6E5"
                        justify="center"
                    >
                        <Text
                            textWeight="600"
                            textSize="title"
                            style={{
                                paddingTop:"2rem",
                                fontStyle: 'italic',
                            }}
                            textColor="black"
                        >
                            return form
                        </Text>
                    </Row>
                    <Row
                        bg="#FFF3F6E5"
                        justify="center"
                        p={{t:"2rem"}}
                    >
                        <Input
                            placeholder="Name"
                            borderColor="white"
                            w={{xs:"16rem", sm:"20rem", md:"30rem"}}
                            h="4rem"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </Row>
                    <Row
                        bg="#FFF3F6E5"
                        justify="center"
                        p={{t:"2rem"}}
                    >
                        <Input
                            placeholder="Email"
                            borderColor="white"
                            w={{xs:"16rem", sm:"20rem", md:"30rem"}}
                            h="4rem"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Row>
                    <Row
                        bg="#FFF3F6E5"
                        justify="center"
                        p={{t:"2rem"}}
                    >
                        <Input
                            placeholder="Order ID Number"
                            borderColor="white"
                            w={{xs:"16rem", sm:"20rem", md:"30rem"}}
                            h="4rem"
                            value={subject}
                            onChange={(e) => setSubject(e.target.value)}
                        />
                    </Row>
                    <Row
                        bg="#FFF3F6E5"
                        justify="center"
                        p={{t:"2rem"}}
                    >
                        <Input
                            placeholder="Reason for Return"
                            borderColor="white"
                            w={{xs:"16rem", sm:"20rem", md:"30rem"}}
                            h="4rem"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                        />
                    </Row>
                    <Row
                        bg="#FFF3F6E5"
                        justify="center"
                        p={{t:"2rem", b:"4rem"}}
                    >
                        {emailSent ? (
                            <Text
                                textWeight="600"
                                textSize="caption"
                                style={{
                                    paddingTop:"1rem",
                                    paddingLeft:"6%",
                                    paddingRight: "6%",
                                    display: 'block',
                                    textAlign: 'center'
                                }}
                            >
                                Return confirmed. Your return will be processed shortly.
                            </Text>
                        ) : (
                            <Button
                                w="35%"
                                rounded="circle"
                                top="1rem"
                                hoverBg="grey"
                                onClick={submit}
                                disabled={(!name || !email || !subject || !message)}
                            >
                                Submit Return
                            </Button>
                        )}
                    </Row>
                </Container>
            </Div>
        </div>
    )
}

export default ReturnPage;