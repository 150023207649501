import React, { useEffect, useContext } from 'react';
import { Container, Row, Text, Div, Col } from 'atomize';
import { Link } from 'react-router-dom';
import { ShopContext } from '../context/shopContext';
import {Helmet} from 'react-helmet'

const TermsConditionsPage = () => {
    const { resetContext } = useContext(ShopContext);
    useEffect(() => {
        resetContext();
        return () => {
        };
    }, [resetContext])
    return (
        <div>
            <Helmet>
                <title>Terms and Conditions</title>
            </Helmet>
            <Container>
            <Row
                style={{paddingTop:'1rem'}}
                justify="center"
            >
                <Text
                    textColor="black"
                    textWeight="700"
                    textSize={{xs: "display1", sm:"display2", md:"display3"}}
                    style={{
                        fontStyle: 'italic',
                    }}
                >
                    w e b s i t e
                </Text>
                <Row
                    justify="center"
                    m={{t:{xs:"1.25rem", sm:"1.4rem", md:"2rem"}}}
                    style={{
                        paddingLeft: '1rem',
                        paddingRight: '1rem'
                    }}
                >
                    <img src={require('../images/star.png')} alt="star" width="25%" height="35%"/>
                </Row>
                <Text
                    textColor="black"
                    textWeight="700"
                    textSize={{xs: "display1", sm:"display2", md:"display3"}}
                    style={{
                        fontStyle: 'italic',
                    }}
                >
                    t e r m s
                </Text>
            </Row>
            </Container>
            <Div
                h="100%"
                bgImg={require('../images/terms.jpeg')}
                bgPos="center center"
                bgSize="cover"
                style={{marginTop:"2rem", marginBottom:'-2rem', paddingBottom:"8rem", imageRendering:"smooth"}}
                justify="center"
            >
                <Container
                    p={{t:"6rem", l:{xs:"2rem", sm:"3rem", md:"7rem"}, r:{xs:"2rem", sm:"3rem", md:"7rem"}, b:"6rem"}}
                >
                    <Col
                        bg="#FFF3F6E5"
                    >
                        <Row
                            justify="center"
                        >
                            <Text
                                textWeight="600"
                                textSize="title"
                                style={{
                                    paddingTop:"4rem",
                                    fontStyle: 'italic',
                                    display: 'block',
                                    textAlign: 'center'
                                }}
                                textColor="#EE3F84"
                            >
                                1. Terms
                            </Text>
                        </Row>
                        <Row
                            justify="center"
                        >
                            <Text
                                textWeight="600"
                                textSize="subheader"
                                style={{
                                    paddingTop:"1rem",
                                    paddingLeft:"6%",
                                    paddingRight:"6%",
                                    display: 'block',
                                    textAlign: 'center'
                                }}
                            >
                                By accessing this Website, accessible from https://www.shopskswim.com/, you are agreeing to be bound by these Website Terms and Conditions of Use and agree that you are responsible for the agreement with any applicable local laws. If you disagree with any of these terms, you are prohibited from accessing this site. The materials contained in this Website are protected by copyright and trademark law.
                            </Text>
                        </Row>
                        <Row
                            justify="center"
                        >
                            <Text
                                textWeight="600"
                                textSize="title"
                                style={{
                                    paddingTop:"2rem",
                                    fontStyle: 'italic',
                                    display: 'block',
                                    textAlign: 'center'
                                }}
                                textColor="#EE3F84"
                            >
                                2. Use License
                            </Text>
                        </Row>
                        <Row
                            justify="center"
                        >
                            <Text
                                textWeight="600"
                                textSize="subheader"
                                style={{
                                    paddingTop:"1rem",
                                    paddingLeft:"6%",
                                    paddingRight:"6%",
                                    display: 'block',
                                    textAlign: 'center'
                                }}
                            >
                                Permission is granted to temporarily download one copy of the materials on SK Swim's Website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license, you may not: (1) modify of copy the materials; (2) use the materials for any commercial purpose or for any public display; (3) attempt to reverse engineer any software contained on SK Swim's Website; (4) remove any copyright or other proprietary notations from the materials; or (5) transferring the materials to another person or "mirror" the materials on any other server.
                            </Text>
                            <Text
                                textWeight="600"
                                textSize="subheader"
                                style={{
                                    paddingTop:"1rem",
                                    paddingLeft:"6%",
                                    paddingRight:"6%",
                                    display: 'block',
                                    textAlign: 'center'
                                }}
                            >
                                This will let SK Swim terminate upon violations of any of these restrictions. Upon termination, your viewing right will also be terminated and you should destroy any downloaded materials in your possession whether it is printed or electronic format.
                            </Text>
                        </Row>
                        <Row
                            justify="center"
                        >
                            <Text
                                textWeight="600"
                                textSize="title"
                                style={{
                                    paddingTop:"2rem",
                                    fontStyle: 'italic',
                                    display: 'block',
                                    textAlign: 'center'
                                }}
                                textColor="#EE3F84"
                            >
                                3. Disclaimer
                            </Text>
                        </Row>
                        <Row
                            justify="center"
                        >
                            <Text
                                textWeight="600"
                                textSize="subheader"
                                style={{
                                    paddingTop:"1rem",
                                    paddingLeft:"6%",
                                    paddingRight:"6%",
                                    display: 'block',
                                    textAlign: 'center'
                                }}
                            >
                                All the materials on SK Swim’s Website are provided "as is". SK Swim makes no warranties, may it be expressed or implied, therefore negates all other warranties. Furthermore, SK Swim does not make any representations concerning the accuracy or reliability of the use of the materials on its Website or otherwise relating to such materials or any sites linked to this Website. These Terms of Service has been created with the help of the <a style={{color:"#EE3F84"}} href="https://www.termsofservicegenerator.net/" target="_blank">Terms Of Service Generator</a>.
                            </Text>
                        </Row>
                        <Row
                            justify="center"
                        >
                            <Text
                                textWeight="600"
                                textSize="title"
                                style={{
                                    paddingTop:"2rem",
                                    fontStyle: 'italic',
                                    display: 'block',
                                    textAlign: 'center'
                                }}
                                textColor="#EE3F84"
                            >
                                4. Limitations
                            </Text>
                        </Row>
                        <Row
                            justify="center"
                        >
                            <Text
                                textWeight="600"
                                textSize="subheader"
                                style={{
                                    paddingTop:"1rem",
                                    paddingLeft:"6%",
                                    paddingRight:"6%",
                                    display: 'block',
                                    textAlign: 'center'
                                }}
                            >
                                SK Swim or its suppliers will not be held accountable for any damages that will arise with the use or inability to use the materials on SK Swim’s Website, even if SK Swim or an authorized representative of this Website has been notified, orally or written, of the possibility of such damage. Some jurisdiction does not allow limitations on implied warranties or limitations of liability for incidental damages, these limitations may not apply to you.
                            </Text>
                        </Row>
                        <Row
                            justify="center"
                        >
                            <Text
                                textWeight="600"
                                textSize="title"
                                style={{
                                    paddingTop:"2rem",
                                    fontStyle: 'italic',
                                    display: 'block',
                                    textAlign: 'center'
                                }}
                                textColor="#EE3F84"
                            >
                                5. Revisions
                            </Text>
                        </Row>
                        <Row
                            justify="center"
                        >
                            <Text
                                textWeight="600"
                                textSize="subheader"
                                style={{
                                    paddingTop:"1rem",
                                    paddingLeft:"6%",
                                    paddingRight:"6%",
                                    display: 'block',
                                    textAlign: 'center'
                                }}
                            >
                                The materials appearing on SK Swim’s Website may include technical, typographical, or photographic errors. SK Swim will not promise that any of the materials in this Website are accurate, complete, or current. SK Swim may change the materials contained on its Website at any time without notice. SK Swim does not make any commitment to update the materials.
                            </Text>
                        </Row>
                        <Row
                            justify="center"
                        >
                            <Text
                                textWeight="600"
                                textSize="title"
                                style={{
                                    paddingTop:"2rem",
                                    fontStyle: 'italic',
                                    display: 'block',
                                    textAlign: 'center'
                                }}
                                textColor="#EE3F84"
                            >
                                6. Links
                            </Text>
                        </Row>
                        <Row
                            justify="center"
                        >
                            <Text
                                textWeight="600"
                                textSize="subheader"
                                style={{
                                    paddingTop:"1rem",
                                    paddingLeft:"6%",
                                    paddingRight:"6%",
                                    display: 'block',
                                    textAlign: 'center'
                                }}
                            >
                                SK Swim has not reviewed all of the sites linked to its Website and is not responsible for the contents of any such linked site. The presence of any link does not imply endorsement by SK Swim of the site. The use of any linked website is at the user’s own risk.
                            </Text>
                        </Row>
                        <Row
                            justify="center"
                        >
                            <Text
                                textWeight="600"
                                textSize="title"
                                style={{
                                    paddingTop:"2rem",
                                    fontStyle: 'italic',
                                    display: 'block',
                                    textAlign: 'center'
                                }}
                                textColor="#EE3F84"
                            >
                                7. Site Terms of Use Modifications
                            </Text>
                        </Row>
                        <Row
                            justify="center"
                        >
                            <Text
                                textWeight="600"
                                textSize="subheader"
                                style={{
                                    paddingTop:"1rem",
                                    paddingLeft:"6%",
                                    paddingRight:"6%",
                                    display: 'block',
                                    textAlign: 'center'
                                }}
                            >
                                SK Swim may revise these Terms of Use for its Website at any time without prior notice. By using this Website, you are agreeing to be bound by the current version of these Terms and Conditions of Use.
                            </Text>
                        </Row>
                        <Row
                            justify="center"
                        >
                            <Text
                                textWeight="600"
                                textSize="title"
                                style={{
                                    paddingTop:"2rem",
                                    fontStyle: 'italic',
                                    display: 'block',
                                    textAlign: 'center'
                                }}
                                textColor="#EE3F84"
                            >
                                8. Your Privacy
                            </Text>
                        </Row>
                        <Row
                            justify="center"
                        >
                            <Text
                                textWeight="600"
                                textSize="subheader"
                                style={{
                                    paddingTop:"1rem",
                                    paddingLeft:"6%",
                                    paddingRight:"6%",
                                    display: 'block',
                                    textAlign: 'center'
                                }}
                            >
                                Please read our <Link to="/privacyPolicy" style={{color: "#EE3F84"}}>Privacy Policy</Link>.
                            </Text>
                        </Row>
                        <Row
                            justify="center"
                        >
                            <Text
                                textWeight="600"
                                textSize="title"
                                style={{
                                    paddingTop:"2rem",
                                    fontStyle: 'italic',
                                    display: 'block',
                                    textAlign: 'center'
                                }}
                                textColor="#EE3F84"
                            >
                                9. Governing Law
                            </Text>
                        </Row>
                        <Row
                            justify="center"
                        >
                            <Text
                                textWeight="600"
                                textSize="subheader"
                                style={{
                                    paddingTop:"1rem",
                                    paddingLeft:"6%",
                                    paddingRight:"6%",
                                    display: 'block',
                                    textAlign: 'center'
                                }}
                            >
                                Any claim related to SK Swim's Website shall be governed by the laws of us without regard to its conflict of law provisions.
                            </Text>
                        </Row>
                        <Row
                            justify="center"
                        >
                            <Text
                                textWeight="600"
                                textSize="caption"
                                style={{
                                    paddingTop:"2rem",
                                    paddingBottom:"4rem",
                                    paddingLeft:"6%",
                                    paddingRight:"6%",
                                    display: 'block',
                                    textAlign: 'center'
                                }}
                            >
                                Last updated 11/22/2022.
                            </Text>
                        </Row>
                    </Col>
                </Container>
            </Div>
        </div>
    )
}

export default TermsConditionsPage;