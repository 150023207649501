import React, { useContext } from 'react'
import { Div, SideDrawer, Text, Row, Col, Anchor, Button, Container, Icon } from "atomize";
import {ShopContext} from '../context/shopContext'
import { Link } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';

const Cart = () => {

    const { isCartOpen, closeCart, checkout, removeItemFromCheckout } = useContext(ShopContext)
    ReactPixel.init('2052293924981104', {}, { debug: true, autoConfig: false });

    if (checkout?.lineItems) {
        return (
            <SideDrawer isOpen={isCartOpen} onClose={closeCart}>
                <Container
                    d="flex"
                    flexDir="column"
                    overflow="scroll"
                >
                    <Row justify="center" border={{ b: '1px solid' }} p="1rem" borderColor="gray300">
                        <Text
                            textWeight= '800'
                            textSize="subtitle"
                            style={{
                                display: 'block',
                                textAlign: 'center'
                            }}
                        >
                            Cart
                        </Text>
                        <Anchor
                            onClick={() => closeCart()}
                            d={{xs:"block", sm:"none"}}
                            m={{t:"-.1rem", l:".5rem"}}
                        >
                            <Icon
                                name="Cross"
                                color="black"
                            />
                        </Anchor>
                    </Row>
                    <Row
                        flexGrow="2"
                        p="0.7rem"
                        overflow="auto"
                        flexWrap="nowrap"
                        flexDir="column">
                        {checkout.lineItems?.length === 0 ?
                            <Row>
                                <Col
                                >
                                    <Row
                                        justify="center"
                                        p={{t:"5rem"}}

                                    >
                                        <Text
                                            tag="h1"
                                            textColor="black"
                                            textSize="paragraph"
                                            transition="0.3s"
                                            style={{
                                                display: 'block',
                                                textAlign: 'center',
                                                fontStyle: 'italic',
                                            }}
                                        >
                                                Your cart is empty.
                                        </Text>
                                    </Row>
                                    <Row
                                        justify="center"
                                    >
                                        <Link
                                            to="/shop" 
                                            onClick={() => closeCart()}
                                            style={{textDecoration:'none'}}
                                        >
                                            <Text
                                                tag="h1"
                                                textColor="#EE3F84"
                                                textSize="paragraph"
                                                transition="0.3s"
                                                p={{ t:"1rem" }}
                                                style={{
                                                    display: 'block',
                                                    textAlign: 'center',
                                                }}
                                            >
                                                Shop All
                                            </Text>
                                        </Link>
                                    </Row>
                                    <Row
                                        justify="center"
                                    >
                                        <Link
                                            to="/shop?type=bestsellers" 
                                            onClick={() => closeCart()}
                                            style={{textDecoration:'none'}}
                                        >
                                            <Text
                                                tag="h1"
                                                textColor="#EE3F84"
                                                textSize="paragraph"
                                                transition="0.3s"
                                                p={{ t:"1rem" }}
                                                style={{
                                                    display: 'block',
                                                    textAlign: 'center',
                                                }}
                                            >
                                                Shop Best Sellers
                                            </Text>
                                        </Link>
                                    </Row>
                                    <Row
                                        justify="center"
                                    >
                                        <Link
                                            to="/shop?type=backyardBaby"
                                            onClick={() => closeCart()}
                                            style={{textDecoration:'none'}}
                                        >
                                            <Text
                                                tag="h1"
                                                textColor="#EE3F84"
                                                textSize="paragraph"
                                                transition="0.3s"
                                                p={{ t:"1rem", b:"5rem"}}
                                                style={{
                                                    display: 'block',
                                                    textAlign: 'center',
                                                }}
                                            >
                                                Shop Backyard Baby Collection
                                            </Text>
                                        </Link>
                                    </Row>
                                </Col>
                            </Row>
                            :
                            <>
                                {checkout.lineItems?.map(item => (
                                    <Row key={item.id} p={{ y:"1rem" }}>
                                        <Col
                                            size={{xs:"5", sm:"6"}}
                                        >
                                            <Div
                                                bgImg={item.variant.image.src}
                                                bgSize="cover"
                                                bgPos="center"
                                                h="9rem"
                                                w="7rem"
                                            />
                                        </Col>
                                        <Col
                                            size={{xs:"6", sm:"5"}}
                                        >
                                            <Row>
                                                <Text
                                                    textWeight= '600'
                                                    textSize="subtitle"
                                                >
                                                    {item.title}
                                                </Text>
                                            </Row>
                                            <Row
                                                p={{ t:".5rem" }}
                                            >
                                                <Text
                                                    textWeight= '600'
                                                    textSize="caption"
                                                >
                                                    ${item.variant.price.amount}0
                                                </Text>
                                            </Row>
                                            <Row
                                                p={{ t:".5rem" }}
                                            >
                                                <Text
                                                    textWeight= '600'
                                                    textSize="caption"
                                                >
                                                    Size: {item.variant.title}
                                                </Text>
                                            </Row>
                                            <Row
                                                p={{ t:".5rem" }}
                                            >
                                                <Text
                                                    textWeight= '600'
                                                    textSize="caption"
                                                >
                                                    Quantity: {item.quantity}
                                                </Text>
                                            </Row>
                                        </Col>
                                        <Col
                                            size="1"
                                        >
                                            <Anchor
                                                onClick={() => removeItemFromCheckout(item.id)}
                                            >
                                                <Icon
                                                    name="Cross"
                                                    color="black"
                                                />
                                            </Anchor>
                                        </Col>
                                    </Row>
                                ))}
                            </>
                        }
                    </Row>
                    <Row border={{ t: '1px solid' }} p="0.7rem" borderColor="gray300" justify="center">
                            <Text
                                textWeight= '700'
                                textSize="subtitle"
                                style={{
                                    display: 'block',
                                    textAlign: 'center'
                                }}
                            >
                                Subtotal: ${checkout.totalPrice.amount}0
                            </Text>
                        <Anchor
                            w="100%"
                            href={checkout.webUrl}
                            onClick={() => ReactPixel.trackCustom('Initiate checkout', checkout.lineItems)}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <Button
                                w="100%"
                                rounded="circle"
                                m={{t:"1rem", b:"3rem"}}
                                bg="#EE3F84"
                                disabled={checkout.lineItems?.length < 1}
                                href={checkout.webUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={() => window.localStorage.clear()}
                            >
                                Checkout
                            </Button>
                        </Anchor>
                    </Row>
                </Container>
            </SideDrawer>
        )
    }

    return null

}

export default Cart