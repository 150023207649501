import React, { useEffect, useContext } from 'react';
import { Text, Row, Div, Container } from 'atomize';
import { ShopContext } from '../context/shopContext';
import {Helmet} from 'react-helmet'

const FAQPage = () => {
    const { resetContext } = useContext(ShopContext);
    useEffect(() => {
        resetContext();
        return () => {
        };
    }, [resetContext])
    return (
        <div>
            <Helmet>
                <title>FAQ</title>
                <meta name="SK Swim Frequently Asked Questions: Boutique Women's Swimwear" content="Hand or machine wash in cold water and lay flat to dry. SK Swim is exclusively an online company. However, we offer easy exchanges so you can have the perfect fit! Yes, your happiness is our top priority. Sarah Kate and her team want you to rock your suit with pride. If you aren’t completely satisfied, we will either exchange your merchandise or offer you store credit. Shipping is free on all orders. Please allow 2-3 days for the order to be processed before shipment occurs. Once your item has been shipped, you will receive a tracking number."/>
            </Helmet>
            <Container>
                <Row
                    justify="center"
                    style={{paddingTop:'1rem'}}
                    h="100%"
                >
                    <Text
                        textColor="black"
                        textWeight="700"
                        textSize={{xs: "display1", sm:"display2", md:"display3"}}
                        style={{
                            fontStyle: 'italic',
                        }}
                    >
                        f r e q u e n t
                    </Text>
                    <Row
                        justify="center"
                        m={{t:{xs:"1.25rem", sm:"1.4rem", md:"2rem"}}}
                        style={{
                            paddingLeft: '1rem',
                            paddingRight: '1rem'
                        }}
                    >
                        <img src={require('../images/star.png')} alt="star" width="25%" height="35%"/>
                    </Row>
                    <Text
                        textColor="black"
                        textWeight="700"
                        textSize={{xs: "display1", sm:"display2", md:"display3"}}
                        style={{
                            fontStyle: 'italic',
                        }}
                    >
                        q u e s t i o n s
                    </Text>
                </Row>
            </Container>
            <Div
                h="100%"
                bgImg={require('../images/faq.jpeg')}
                bgPos="center center"
                bgSize="cover"
                style={{
                    marginTop:"2rem",
                    marginBottom:'-1.5rem',
                    imageRendering:'smooth'
                }}
                justify="center"
            >
                <Container
                    p={{t:"6rem", l:{xs:"2rem", sm:"3rem", md:"7rem"}, r:{xs:"2rem", sm:"3rem", md:"7rem"}}}
                    >
                    <Row
                        bg="#FFF3F6E5"
                        justify="center"
                    >
                        <Text
                            textWeight="600"
                            textSize="title"
                            style={{
                                paddingTop:"2rem",
                                fontStyle: 'italic',
                                display: 'block',
                                textAlign: 'center'
                            }}
                            textColor="#EE3F84"
                        >
                            1. What do I do if I receive a faulty/ incorrect item?
                        </Text>
                        <Text
                            textWeight="600"
                            textSize="subheader"
                            p={{t:"1rem", l:"6%", r:"6%", b:"2rem"}}
                            style={{
                                display: 'block',
                                textAlign: 'center'
                            }}
                        >
                            Please contact our team at support@shopskswim.com with the reason for your return. We will then email you a return label as well as send you a replacement upon receipt of the item.
                        </Text>
                    </Row>
                </Container>
                <Container
                    p={{t:"2rem", l:{xs:"2rem", sm:"3rem", md:"7rem"}, r:{xs:"2rem", sm:"3rem", md:"7rem"}}}
                    >
                    <Row
                        bg="#FFF3F6E5"
                        justify="center"
                    >
                        <Text
                            textWeight="600"
                            textSize="title"
                            style={{
                                paddingTop:"2rem",
                                fontStyle: 'italic',
                                display: 'block',
                                textAlign: 'center'
                            }}
                            textColor="#EE3F84"
                        >
                            2. How do I care for my SK Swim?
                        </Text>
                    </Row>
                    <Row
                        bg="#FFF3F6E5"
                        justify="center"
                    >
                        <Text
                            textWeight="600"
                            textSize="subheader"
                            p={{t:"1rem", l:"6%", r:"6%", b:"2rem"}}
                            style={{
                                display: 'block',
                                textAlign: 'center'
                            }}
                        >
                            Hand or machine wash in cold water and lay flat to dry.
                        </Text>
                    </Row>
                </Container>
                <Container
                    p={{t:"2rem", l:{xs:"2rem", sm:"3rem", md:"7rem"}, r:{xs:"2rem", sm:"3rem", md:"7rem"}}}
                    >
                    <Row
                        bg="#FFF3F6E5"
                        justify="center"
                    >
                        <Text
                            textWeight="600"
                            textSize="title"
                            style={{
                                paddingTop:"2rem",
                                fontStyle: 'italic',
                                display: 'block',
                                textAlign: 'center'
                            }}
                            textColor="#EE3F84"
                        >
                            3. The item I want is out of stock, what do I do?
                        </Text>
                        <Text
                            textWeight="600"
                            textSize="subheader"
                            p={{t:"1rem", l:"6%", r:"6%", b:"2rem"}}
                            style={{
                                display: 'block',
                                textAlign: 'center'
                            }}
                        >
                            Unfortunately, once an item is sold out it will not be restocked to make room for new styles and patterns.
                        </Text>
                    </Row>
                </Container>
                <Container
                    p={{t:"2rem", l:{xs:"2rem", sm:"3rem", md:"7rem"}, r:{xs:"2rem", sm:"3rem", md:"7rem"}}}
                    >
                    <Row
                        bg="#FFF3F6E5"
                        justify="center"
                    >
                        <Text
                            textWeight="600"
                            textSize="title"
                            style={{
                                paddingTop:"2rem",
                                fontStyle: 'italic',
                                display: 'block',
                                textAlign: 'center'
                            }}
                            textColor="#EE3F84"
                        >
                            4. Do you have anywhere I can try on SK Swim?
                        </Text>
                    </Row>
                    <Row
                        bg="#FFF3F6E5"
                        justify="center"
                    >
                        <Text
                            textWeight="600"
                            textSize="subheader"
                            p={{t:"1rem", l:"6%", r:"6%", b:"2rem"}}
                            style={{
                                display: 'block',
                                textAlign: 'center'
                            }}
                        >
                            SK Swim is exclusively an online company. However, we offer easy exchanges so you can have the perfect fit!
                        </Text>
                    </Row>
                </Container>
                <Container
                    p={{t:"2rem", l:{xs:"2rem", sm:"3rem", md:"7rem"}, r:{xs:"2rem", sm:"3rem", md:"7rem"}}}
                    >
                    <Row
                        bg="#FFF3F6E5"
                        justify="center"
                    >
                        <Text
                            textWeight="600"
                            textSize="title"
                            style={{
                                paddingTop:"2rem",
                                fontStyle: 'italic',
                                display: 'block',
                                textAlign: 'center'
                            }}
                            textColor="#EE3F84"
                        >
                            5. What do I do if my package doesn’t arrive?
                        </Text>
                    </Row>
                    <Row
                        bg="#FFF3F6E5"
                        justify="center"
                    >
                        <Text
                            textWeight="600"
                            textSize="subheader"
                            p={{t:"1rem", l:"6%", r:"6%", b:"2rem"}}
                            style={{
                                display: 'block',
                                textAlign: 'center'
                            }}
                        >
                            Once your package is shipped, you will receive a tracking number. If your package doesn’t arrive, we will contact the carrier. You will be sent a replacement if necessary!
                        </Text>
                    </Row>
                </Container>
                <Container
                    p={{t:"2rem", l:{xs:"2rem", sm:"3rem", md:"7rem"}, r:{xs:"2rem", sm:"3rem", md:"7rem"}}}
                    >
                    <Row
                        bg="#FFF3F6E5"
                        justify="center"
                    >
                        <Text
                            textWeight="600"
                            textSize="title"
                            style={{
                                paddingTop:"2rem",
                                fontStyle: 'italic',
                                display: 'block',
                                textAlign: 'center'
                            }}
                            textColor="#EE3F84"
                        >
                            6. How do SK Swim sizes run?
                        </Text>
                    </Row>
                    <Row
                        bg="#FFF3F6E5"
                        justify="center"
                    >
                        <Text
                            textWeight="600"
                            textSize="subheader"
                            p={{t:"1rem", l:"6%", r:"6%", b:"2rem"}}
                            style={{
                                display: 'block',
                                textAlign: 'center'
                            }}
                        >
                            Our suits tend to run on the smaller size, size up.
                        </Text>
                    </Row>
                </Container>
                <Container
                    p={{t:"2rem", l:{xs:"2rem", sm:"3rem", md:"7rem"}, r:{xs:"2rem", sm:"3rem", md:"7rem"}}}
                    >
                    <Row
                        bg="#FFF3F6E5"
                        justify="center"
                    >
                        <Text
                            textWeight="600"
                            textSize="title"
                            style={{
                                paddingTop:"2rem",
                                fontStyle: 'italic',
                                display: 'block',
                                textAlign: 'center'
                            }}
                            textColor="#EE3F84"
                        >
                            7. How do I start a return?
                        </Text>
                    </Row>
                    <Row
                        bg="#FFF3F6E5"
                        justify="center"
                    >
                        <Text
                            textWeight="600"
                            textSize="subheader"
                            p={{t:"1rem", l:"6%", r:"6%", b:"2rem"}}
                            style={{
                                display: 'block',
                                textAlign: 'center'
                            }}
                        >
                            Please contact us at support@shopskswim.com with the reason for the return. We will send you a return label and issue you a store credit for the total of your returned order. 
                        </Text>
                    </Row>
                </Container>
                <Container
                    p={{t:"2rem", l:{xs:"2rem", sm:"3rem", md:"7rem"}, r:{xs:"2rem", sm:"3rem", md:"7rem"}}}
                    >
                    <Row
                        bg="#FFF3F6E5"
                        justify="center"
                    >
                        <Text
                            textWeight="600"
                            textSize="title"
                            style={{
                                paddingTop:"2rem",
                                fontStyle: 'italic',
                                display: 'block',
                                textAlign: 'center'
                            }}
                            textColor="#EE3F84"
                        >
                            8. Does SK Swim do exchanges and store credit?
                        </Text>
                    </Row>
                    <Row
                        bg="#FFF3F6E5"
                        justify="center"
                    >
                        <Text
                            textWeight="600"
                            textSize="subheader"
                            p={{t:"1rem", l:"6%", r:"6%", b:"2rem"}}
                            style={{
                                display: 'block',
                                textAlign: 'center'
                            }}
                        >
                            Yes, your happiness is our top priority. Sarah Kate and her team want you to rock your suit with pride. If you aren’t completely satisfied, we will either exchange your merchandise or offer you store credit.
                        </Text>
                    </Row>
                </Container>
                <Container
                    p={{t:"2rem", l:{xs:"2rem", sm:"3rem", md:"7rem"}, r:{xs:"2rem", sm:"3rem", md:"7rem"}}}
                    >
                    <Row
                        bg="#FFF3F6E5"
                        justify="center"
                    >
                        <Text
                            textWeight="600"
                            textSize="title"
                            style={{
                                paddingTop:"2rem",
                                fontStyle: 'italic',
                                display: 'block',
                                textAlign: 'center'
                            }}
                            textColor="#EE3F84"
                        >
                            9.Shipping and Handling?
                        </Text>
                    </Row>
                    <Row
                        bg="#FFF3F6E5"
                        justify="center"
                    >
                        <Text
                            textWeight="600"
                            textSize="subheader"
                            p={{t:"1rem", l:"6%", r:"6%", b:"2rem"}}
                            style={{
                                display: 'block',
                                textAlign: 'center'
                            }}
                        >
                            Shipping is free on all orders. Please allow 2-3 days for the order to be processed before shipment occurs. Once your item has been shipped, you will receive a tracking number.
                        </Text>
                    </Row>
                </Container>
                <Container
                    p={{t:"2rem", l:{xs:"2rem", sm:"3rem", md:"7rem"}, r:{xs:"2rem", sm:"3rem", md:"7rem"}, b:"6rem"}}
                    >
                    <Row
                        bg="#FFF3F6E5"
                        justify="center"
                    >
                        <Text
                            textWeight="600"
                            textSize="title"
                            style={{
                                paddingTop:"2rem",
                                fontStyle: 'italic',
                                display: 'block',
                                textAlign: 'center'
                            }}
                            textColor="#EE3F84"
                        >
                            10.Does SK Swim offer express shipping?
                        </Text>
                    </Row>
                    <Row
                        bg="#FFF3F6E5"
                        justify="center"
                    >
                        <Text
                            textWeight="600"
                            textSize="subheader"
                            p={{t:"1rem", l:"6%", r:"6%", b:"2rem"}}
                            style={{
                                display: 'block',
                                textAlign: 'center'
                            }}
                        >
                            Currently, we do not offer express shipping. We are looking into this shipping option for the future. 
                        </Text>
                    </Row>
                </Container>
            </Div>
        </div>
    )
}

export default FAQPage;