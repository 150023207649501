import React, { useEffect, useContext } from 'react';
import { Container, Row, Div, Text } from 'atomize';
import { ShopContext } from '../context/shopContext';
import {Helmet} from 'react-helmet'

const ShippingPolicyPage = () => {
    const { resetContext } = useContext(ShopContext);
    useEffect(() => {
        resetContext();
        return () => {
        };
    }, [resetContext])
    return (
        <div>
            <Helmet>
                <title>Shipping Policy</title>
                <meta name="SK Swim Shipping Policy: Boutique Women's Swimwear" content="We ship from Baton Rouge, Louisiana (USA). All orders are processed within 24 hours if the order is received during business hours, Monday through Friday. All other orders will be processed and then shipped the next business day. Please note that we do not process or ship orders on holidays or weekends. When placing your order, please confirm that all billing and payment information is accurate to avoid any delays in shipment."/>
            </Helmet>
            <Container>
                <Row
                    justify="center"
                    style={{paddingTop:'1rem'}}
                >
                    <Text
                        textColor="black"
                        textWeight="700"
                        textSize={{xs: "display1", sm:"display2", md:"display3"}}
                        style={{
                            fontStyle: 'italic',
                        }}
                    >
                        s h i p p i n g
                    </Text>
                    <Row
                        justify="center"
                        m={{t:{xs:"1.25rem", sm:"1.4rem", md:"2rem"}}}
                        style={{
                            paddingLeft: '1rem',
                            paddingRight: '1rem'
                        }}
                    >
                        <img src={require('../images/star.png')} alt="star" width="25%" height="35%"/>
                    </Row>
                    <Text
                        textColor="black"
                        textWeight="700"
                        textSize={{xs: "display1", sm:"display2", md:"display3"}}
                        style={{
                            fontStyle: 'italic',
                        }}
                    >
                        p o l i c y
                    </Text>
                </Row>
            </Container>
            <Div
                h="100%"
                bgImg={require('../images/shippingPol.png')}
                bgPos="center center"
                bgSize="cover"
                style={{marginTop:"2rem", marginBottom:'-1.5rem', imageRendering:"smooth"}}
                justify="center"
            >
                <Container
                    p={{t:"6rem", l:{xs:"2rem", sm:"3rem", md:"7rem"}, r:{xs:"2rem", sm:"3rem", md:"7rem"}}}
                >
                    <Row
                        bg="#FFF3F6E5"
                        justify="center"
                    >
                        <Text
                            textWeight="600"
                            textSize="title"
                            style={{
                                paddingTop:"2rem",
                                fontStyle: 'italic',
                                display: 'block',
                                textAlign: 'center'
                            }}
                            textColor="#EE3F84"
                        >
                            Temporary Flat Rate Shipping
                        </Text>
                        <Text
                            textWeight="600"
                            textSize="subheader"
                            p={{t:"1rem", l:"6%", r:"6%", b:"2rem"}}
                            style={{
                                display: 'block',
                                textAlign: 'center'
                            }}
                        >
                            We are temporarily shipping some packages using the United States Postal Service’s Priority Mail system. Shipments using this method will be noted at the time of purchase. Standard limitations apply and package tracking will still be available at a standard rate.
                        </Text>
                    </Row>
                </Container>
                <Container
                    p={{t:"2rem", l:{xs:"2rem", sm:"3rem", md:"7rem"}, r:{xs:"2rem", sm:"3rem", md:"7rem"}}}
                >
                    <Row
                        bg="#FFF3F6E5"
                        justify="center"
                    >
                        <Text
                            textWeight="600"
                            textSize="title"
                            style={{
                                paddingTop:"2rem",
                                fontStyle: 'italic',
                                display: 'block',
                                textAlign: 'center'
                            }}
                            textColor="#EE3F84"
                        >
                            Domestic Shipping
                        </Text>
                        <Text
                            textWeight="600"
                            textSize="subheader"
                            p={{t:"1rem", l:"6%", r:"6%"}}
                            style={{
                                display: 'block',
                                textAlign: 'center'
                            }}
                        >
                            We ship from Baton Rouge, Louisiana (USA). All orders are processed within 24 hours if the order is received during business hours, Monday through Friday. All other orders will be processed and then shipped the next business day. Please note that we do not process or ship orders on holidays or weekends. When placing your order, please confirm that all billing and payment information is accurate to avoid any delays in shipment.
                        </Text>
                        <Text
                            textWeight="600"
                            textSize="subheader"
                            p={{t:"1rem", l:"6%", r:"6%", b:"2rem"}}
                            style={{
                                display: 'block',
                                textAlign: 'center'
                            }}
                        >
                            Signature upon delivery is not required. Please contact UPS or Customer Service if you would like to add a required signature to your delivery. We do not take responsibility for lost/missing packages that show successful delivery to the order address.
                        </Text>
                    </Row>
                </Container>
                <Container
                    p={{t:"2rem", l:{xs:"2rem", sm:"3rem", md:"7rem"}, r:{xs:"2rem", sm:"3rem", md:"7rem"}, b:"6rem"}}
                >
                    <Row
                        bg="#FFF3F6E5"
                        justify="center"
                    >
                        <Text
                            textWeight="600"
                            textSize="title"
                            style={{
                                paddingTop:"2rem",
                                fontStyle: 'italic',
                                display: 'block',
                                textAlign: 'center'
                            }}
                            textColor="#EE3F84"
                        >
                            International Shipping
                        </Text>
                        <Text
                            textWeight="600"
                            textSize="subheader"
                            p={{t:"1rem", l:"6%", r:"6%", b:"2rem"}}
                            style={{
                                display: 'block',
                                textAlign: 'center'
                            }}
                        >
                            ALL INTERNATIONAL ORDERS ARE FINAL SALE (ANY COUNTRY OUTSIDE THE UNITED STATES). SK Swim ships internationally with the assistance of DHL. All customs/taxes/duties/shipping costs are calculated at the checkout page. SK Swim cannot control and is not responsible for any duties/taxes applied to your package. Customs policies vary widely from country to country; please contact your local customs office for further information. Note, on rare occasions, customs agents may delay the delivery of some packages. If you refuse a shipment from SK Swim, you are still responsible for the original shipping charges and any import fees, duties, and/or taxes that are incurred on the package. DHL will not return the package to us and as such we will not provide a refund of any kind.
                        </Text>
                    </Row>
                </Container>
            </Div>
        </div>
    )
}

export default ShippingPolicyPage;