import React from 'react';
import { Dropdown } from "atomize";


class HoverDropdown extends React.Component {
    render() {
        const { title, list } = this.props;
        return (
            <Dropdown
                targetHover
                menu={list}
                borderColor="white"
                hoverBg="white"
                focusBg="white"
                hoverBorderColor="white"
                focusBorderColor="white"
                textColor="black"
                textWeight="700"
                style={{
                    zIndex:'600'
                }}
            >
                {title}
            </Dropdown>
        );
    }
}

export default HoverDropdown;