import React, { useEffect, useContext, useState } from 'react';
import { Container, Div, Row, Text, Input, Textarea, Button } from 'atomize';
import { Link } from 'react-router-dom';
import emailjs from 'emailjs-com'
import { ShopContext } from '../context/shopContext';
import {Helmet} from 'react-helmet'

const ContactPage = () => {
    const { resetContext } = useContext(ShopContext);
    useEffect(() => {
        resetContext();
        return () => {
        };
    }, [resetContext])
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [emailSent, setEmailSent] = useState(false);

    const submit = () => {
        const serviceId = 'service_9w1l4xk';
        const templateId = 'template_r0o83bk';
        const userId = 'cb6yeJTXPsKDpEUPR';
        const templateParams = {
            name,
            email,
            subject,
            message
        };

        emailjs.send(serviceId, templateId, templateParams, userId)
            .then(response => console.log(response))
            .then(error => console.log(error));
        setName('');
        setEmail('');
        setMessage('');
        setSubject('')
        confirmEmail();
    }

    const delay = ms => new Promise(res => setTimeout(res, ms));

    const confirmEmail = async () => {
        setEmailSent(true);
        await delay(3000);
        setEmailSent(false);
      };

    return (
        <div>
            <Helmet>
                <title>Contact Us</title>
                <meta name="Contact SK Swim: Boutique Women's Swimwear- We are here to help you with whatever you need!" content="We are passionate about our products and dedicated to providing a fantastic experience for every customer. Our friendly and talented staff is ready to answer questions and help you find exactly what you are looking for." />
            </Helmet>
            <Container>
                <Row
                    justify="center"
                    style={{paddingTop:'1rem'}}
                >
                    <Text
                        textColor="black"
                        textWeight="700"
                        textSize={{xs: "display1", sm:"display2", md:"display3"}}
                        style={{
                            fontStyle: 'italic',
                        }}
                    >
                        c o n t a c t
                    </Text>
                    <Row
                        justify="center"
                        m={{t:{xs:"1.25rem", sm:"1.4rem", md:"2rem"}}}
                        style={{
                            paddingLeft: '1rem',
                            paddingRight: '1rem'
                        }}
                    >
                        <img src={require('../images/star.png')} alt="star" width="25%" height="35%"/>
                    </Row>
                    <Text
                        textColor="black"
                        textWeight="700"
                        textSize={{xs: "display1", sm:"display2", md:"display3"}}
                        style={{
                            fontStyle: 'italic',
                        }}
                    >
                        u s
                    </Text>
                </Row>
            </Container>
            <Div
                h="100%"
                bgImg={require('../images/contact.jpeg')}
                bgPos="center center"
                bgSize="cover"
                justify="center"
                style= {{
                    marginTop:"2rem", 
                    imageRendering:'smooth'
                }}
            >
                <Container
                    p={{t:"6rem", l:{xs:"2rem", sm:"3rem", md:"7rem"}, r:{xs:"2rem", sm:"3rem", md:"7rem"}, b:"6rem"}}
                >
                    <Row
                        bg="#FFF3F6E5"
                        justify="center"
                    >
                        <Text
                            textWeight="600"
                            textSize="title"
                            style={{
                                paddingTop:"2rem",
                                fontStyle: 'italic',
                            }}
                            textColor="black"
                        >
                            contact
                        </Text>
                    </Row>
                    <Row
                        bg="#FFF3F6E5"
                        justify="center"
                        p={{t:"2rem"}}
                    >
                        <Input
                            placeholder="Name"
                            borderColor="white"
                            w={{xs:"16rem", sm:"20rem", md:"30rem"}}
                            h="4rem"
                            onChange={(e) => setName(e.target.value)}
                            value={name}
                        />
                    </Row>
                    <Row
                        bg="#FFF3F6E5"
                        justify="center"
                        p={{t:"2rem"}}
                    >
                        <Input
                            placeholder="Email"
                            borderColor="white"
                            w={{xs:"16rem", sm:"20rem", md:"30rem"}}
                            h="4rem"
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                        />
                    </Row>
                    <Row
                        bg="#FFF3F6E5"
                        justify="center"
                        p={{t:"2rem"}}
                    >
                        <Input
                            placeholder="Subject"
                            borderColor="white"
                            w={{xs:"16rem", sm:"20rem", md:"30rem"}}
                            h="4rem"
                            onChange={(e) => setSubject(e.target.value)}
                            value={subject}
                        />
                    </Row>
                    <Row
                        bg="#FFF3F6E5"
                        justify="center"
                        p={{t:"2rem"}}
                    >
                        <Textarea
                            placeholder="Message"
                            w={{xs:"16rem", sm:"20rem", md:"30rem"}}
                            style={{
                                marginTop: '.25rem'
                            }}
                            borderColor="white"
                            onChange={(e) => setMessage(e.target.value)}
                            value={message}
                        />
                    </Row>
                    <Row
                        bg="#FFF3F6E5"
                        justify="center"
                        p={{t:"2rem", b:"4rem"}}
                    >
                        {emailSent ? (
                            <Text
                                textWeight="600"
                                textSize="caption"
                                style={{
                                    paddingTop:"1rem",
                                    paddingLeft:"6%",
                                    paddingRight: "6%",
                                    display: 'block',
                                    textAlign: 'center'
                                }}
                            >
                                Thank you for your message, we will be in touch in no time!
                            </Text>
                        ) : (
                            <Button
                                w="35%"
                                rounded="circle"
                                top="1rem"
                                hoverBg="grey"
                                onClick={submit}
                                disabled={(!name || !email || !subject || !message)}
                            >
                                Submit
                            </Button>
                        )}
                    </Row>
                </Container>
            </Div>
            <Div
                w="100%"
                style={{paddingBottom:"4rem"}}
                bg="#FFF3F6"
            >
                <Text
                    textWeight="600"
                    textSize="title"
                    style={{
                        paddingTop:"3rem",
                        fontStyle: 'italic',
                        paddingLeft:"6%",
                        paddingRight: "6%",
                        display: 'block',
                        textAlign: 'center'
                    }}
                    textColor="#EE3F84"
                >
                    We are here to help you with whatever you need!
                </Text>
                <Text
                    textWeight="600"
                    textSize="subheader"
                    style={{
                        paddingTop:"1rem",
                        paddingLeft:"6%",
                        paddingRight: "6%",
                        display: 'block',
                        textAlign: 'center'
                    }}
                >
                    We are passionate about our products and dedicated to providing a fantastic experience for every customer.
                </Text>
                <Text
                    textWeight="600"
                    textSize="subheader"
                    style={{
                        paddingTop:"1rem",
                        paddingLeft:"6%",
                        paddingRight: "6%",
                        display: 'block',
                        textAlign: 'center'
                    }}
                >
                    Thank you for browsing the shopskswim.com website. Please reach out Monday through Friday from 9 AM to 5 PM CST using the support form on this page for the fastest response. 
                </Text>
                <Text
                    textWeight="600"
                    textSize="subheader"
                    style={{
                        paddingTop:"1rem",
                        paddingLeft:"6%",
                        paddingRight: "6%",
                        display: 'block',
                        textAlign: 'center'
                    }}
                >
                    Our friendly and talented staff is ready to answer questions and help you find exactly what you are looking for.
                </Text>
                <Text
                    textWeight="600"
                    textSize="subheader"
                    style={{
                        paddingTop:"1rem",
                        paddingLeft:"6%",
                        paddingRight: "6%",
                        display: 'block',
                        textAlign: 'center'
                    }}
                >
                    Trouble using our support form? Reach out to us directly.
                </Text>
                <Text
                    textWeight="800"
                    textSize="subheader"
                    style={{
                        paddingTop:"1rem",
                        paddingLeft:"6%",
                        paddingRight: "6%",
                        display: 'block',
                        textAlign: 'center'
                    }}
                >
                    DIRECT EMAIL:
                </Text>
                <a
                    href="mailto:support@shopskswim.com"
                    target="_blank"
                >
                    <Link
                        style={{
                            paddingTop:"1rem",
                            color:"#EE3F84",
                            paddingLeft:"6%",
                            paddingRight: "6%",
                            display: 'block',
                            textAlign: 'center'
                        }}
                    >
                        support@shopskswim.com
                    </Link>
                </a>
            </Div>
        </div>
    )
}

export default ContactPage;