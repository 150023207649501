import React, { useState, useEffect, useContext } from 'react';
import { ShopContext } from '../context/shopContext';
import { Text, Div, Row, Col, Container, Button, Radiobox } from 'atomize';
import { Link } from 'react-router-dom';
import Insta from '../components/Instagram/Insta'
import Loading from '../components/Loading';
import {Helmet} from 'react-helmet'

const HomePage = () => {
    const { resetContext, bestsellers, fetchBestsellers } = useContext(ShopContext);
    const [index, setIndex] = useState(0);
    const carosel = ["All", "Men's", "Bestsellers", "Backyard"]

    const photoParamMapping = {
        // "All": require('../images/suitup.png'),
        "Bestsellers": require('../images/bestsellers.png'),
        "All": require('../images/paradiseHome.png'),
        "Backyard": require('../images/backyard.png'),
        "Men's": require('../images/mens.png'),
    }

    const hrefMapping = {
        // "All": "/shop",
        "Bestsellers": "shop?type=bestsellers",
        "All": "shop?type=swim",
        "Backyard": "shop?type=backyardBaby",
        "Men's": "/shop?type=mens",
    }

    useEffect(() => {
        resetContext();
        fetchBestsellers();
        return () => {
        };
    }, [fetchBestsellers])

    if(!bestsellers) return <Loading/>
    return (
        <div>
            <Helmet>
                <title>Home</title>
                <meta name="SK Swim Home: Boutique Women's Swimwear- Suit Up." content="“We hope to motivate women to feel beautiful and confident while rocking a swimsuit. Our desire to empower women wearing our swimwear designs are what make the brand magic!”" />
            </Helmet>
            <Col>
                <Row
                    justify="center"
                >
                    <Col
                    >
                        <Div
                            bgImg={photoParamMapping[carosel[index]]}
                            bgPos="center center"
                            bgSize="cover"
                            h={{xs:"12rem", sm:"22rem", md:"30rem", lg:"38rem"}}
                            style={{
                                top: '6rem',
                                imageRendering:'smooth'
                            }}
                            justify="center"
                            
                        />
                    </Col>
                </Row>
            <Container>
                    <Col>
                        <Row
                            justify="center"
                            m={{t:{xs:"-7rem", sm:"-7rem", md:"-8rem", lg:"-9rem"}}}
                        >
                            <Link
                                to={hrefMapping[carosel[index]]}
                                style={{
                                    textDecoration: 'none'
                                }}
                            >
                                <Button
                                    w={{xs:"6rem", sm:"10rem", md:"20rem"}}
                                    h={{xs:"2rem", sm:"2rem", md:"3rem"}}
                                    rounded="circle"
                                    top="1rem"
                                    textSize={{xs:"tiny", sm:"caption", md:"body"}}
                                >
                                    Shop {carosel[index]}
                                </Button>
                            </Link>
                        </Row>
                        <Row
                            top={{xs:"8rem", sm:"12rem", md:"14rem", lg:"16rem", xl:"20rem"}}
                            justify="center"
                            m={{t:"2rem"}}
                        >
                            <Div
                                onClick={() => setIndex(0)}
                            >
                                <Radiobox
                                    activeColor="#EE3F84"
                                    inactiveColor="white"
                                    m={{r:"1rem"}}
                                    checked={ carosel[index] === "All" }
                                >
                                </Radiobox>
                            </Div>
                            <Div
                                onClick={() => setIndex(1)}
                            >
                                <Radiobox
                                    activeColor="#EE3F84"
                                    inactiveColor="white"
                                    m={{r:"1rem"}}
                                    checked={ carosel[index] === "Men's" }
                                >
                                </Radiobox>
                            </Div>
                            <Div
                                onClick={() => setIndex(2)}
                            >
                                <Radiobox
                                    activeColor="#EE3F84"
                                    inactiveColor="white"
                                    m={{r:"1rem"}}
                                    checked={ carosel[index] === "Bestsellers" }
                                >
                                </Radiobox>
                            </Div>
                            <Div
                                onClick={() => setIndex(3)}
                            >
                                <Radiobox
                                    activeColor="#EE3F84"
                                    inactiveColor="white"
                                    m={{r:"1rem"}}
                                    checked={ carosel[index] === "Backyard" }
                                >
                                </Radiobox>
                            </Div>
                            {/* <Div
                                onClick={() => setIndex(4)}
                            >
                                <Radiobox
                                    activeColor="#EE3F84"
                                    inactiveColor="white"
                                    m={{r:"1rem"}}
                                    checked={ carosel[index] === "Men's" }
                                >
                                </Radiobox>
                            </Div> */}
                        </Row>
                    </Col>
                <Row
                    style={{
                        display: 'block'                        
                    }}
                >
                    <Row
                        p={{t:{xs:"2rem", sm:"2rem", md:"3rem", lg:"4rem"}}}
                        justify="center"
                    >
                        <div>
                            <Text
                                textColor="black"
                                textWeight="600"
                                textSize={{xs:"subheader", sm:"heading"}}
                            >
                                Best Sellers
                            </Text>
                        </div>
                    </Row>
                    <Row
                        justify="center"
                    >
                        <div 
                            style={{
                                display: 'flex',
                                overflowX: 'scroll'
                            }}
                        >
                            {bestsellers.map((product) => {
                                return (
                                    <Col key={product.id} size="4" >
                                        <Link to={`/product/${product.id.substring(product.id.indexOf('t') + 2)}`} style={{ textDecoration: 'none' }}>
                                            <Div
                                                h={{xs:"12rem", sm:"16rem", md:"20rem"}}
                                                w={{xs:"7rem", sm:"12rem", md: "15rem"}}
                                                hoverShadow="5"
                                                bgSize="cover"
                                                bgPos="center center"
                                                m={{ t: "1.5rem"}}
                                                bgImg={product.images[0].src}
                                            />                                 
                                            <Text                                 
                                                textWeight= '600'
                                                textColor="black"
                                                m={{t:"1rem"}}
                                            >
                                                {product.title}
                                            </Text>                                   
                                        </Link>
                                    </Col>
                                );
                            })}
                        </div>
                    </Row>
                </Row>
                <Row
                    style={{
                        display: 'block',
                    }}
                    justify="center"
                    p={{xs:"2rem", sm: "3rem"}}
                >
                    <Text 
                        textWeight="600"
                        textSize={{xs:"paragraph", sm:"subheader"}}
                        textColor='black'
                        textAlign="center"
                    >
                        “Our desire is to empower women to feel <em>beautiful</em> and <em>confident</em> while rocking SK Swim. We design the perfect suit, but YOU are what makes the brand <em>magic</em>!”
                    </Text>
                    <Row
                        justify="center"
                        style={{
                        }}
                    >
                        <img src={require('../images/star.png')} alt="star" width="5%"/>
                    </Row>
                </Row>
            </Container>
            <Row
                justify="center"
            >
                <Col
                    display='block'
                >
                    <Div
                        bgImg={require('../images/skswim.jpg')}
                        bgPos="center center"
                        bgSize="cover"
                        h={{xs:"12rem", sm:"22rem", md:"30rem", lg:"38rem"}}
                        style={{
                            imageRendering:'smooth'
                        }}
                        justify="center"
                        
                    />
                </Col>
            </Row>
            <Div
                justify="center"
                style={{
                    marginBottom: '2rem',
                }}
            >
                <Row
                    style={{
                        display: 'row',
                        marginTop:'4rem',
                        marginLeft:".001rem"
                    }}
                    w="100%"
                    
                >
                    <Col
                        style={{
                            flexDirection: 'column',
                            display: 'flex',
                        }}
                        size="4"
                    >
                        <Link style={{ textDecoration: 'none' }} to="/shop?type=tops">
                            <Div
                                h={{xs:"24rem", sm:"28rem", md:"32rem"}}
                                w="100%"
                                bgImg={require('../images/tops.jpeg')}
                                bgSize="cover"
                                bgPos="center center"
                                style={{
                                    imageRendering:'smooth'
                                }}
                            />                                   
                            <Row
                                justify="center"
                            >
                                <Button
                                    w="75%"
                                    rounded="circle"
                                    style={{
                                        marginTop:'-4rem'
                                    }}
                                >
                                    Tops
                                </Button>
                            </Row>
                        </Link>
                    </Col>
                    <Col
                        style={{
                            flexDirection: 'column',
                            display: 'flex',
                        }}
                        size="4"
                    >
                        <Link style={{ textDecoration: 'none' }} to="/shop?type=bottoms">
                            <Div
                                h={{xs:"24rem", sm:"28rem", md:"32rem"}}
                                w="100%"
                                bgImg={require('../images/bottoms.jpeg')}
                                bgSize="cover"
                                bgPos="70%, 50%"
                                style={{
                                    imageRendering:'smooth'
                                }}
                            />                                   
                            <Row
                                justify="center"
                            >
                                <Button
                                    w="75%"
                                    rounded="circle"
                                    style={{
                                        marginTop:'-4rem'
                                    }}
                                >
                                    Bottoms
                                </Button>
                            </Row>
                        </Link>
                    </Col>
                    <Col
                        style={{
                            flexDirection: 'column',
                            display: 'flex',
                        }}
                        size="4"
                    >
                        <Link style={{ textDecoration: 'none' }} to="/shop?type=coverups">
                            <Div
                                h={{xs:"24rem", sm:"28rem", md:"32rem"}}
                                w="100%"
                                bgImg={require('../images/coverups.jpeg')}
                                bgSize="cover"
                                bgPos="30%, 10%"
                                style={{
                                    imageRendering:'smooth'
                                }}
                            />                                   
                            <Row
                                justify="center"
                            >
                                <Button
                                    w="75%"
                                    rounded="circle"
                                    style={{
                                        marginTop:'-4rem'
                                    }}
                                >
                                    Cover Ups
                                </Button>
                            </Row>
                        </Link>
                    </Col>
                </Row>
            </Div>
            {/* <Row
                justify="center"
            >
                <Col
                    display='block'
                >
                    <Div
                        bgImg={require('../images/blue.png')}
                        bgPos="center center"
                        bgSize="cover"
                        h={{xs:"12rem", sm:"22rem", md:"30rem", lg:"38rem"}}
                        style={{
                            marginTop:"2rem",
                            imageRendering:'smooth'
                        }}
                        justify="center"
                    />
                </Col>
            </Row> */}
            <Container
                style={{
                    marginBottom: '6rem',
                }}
                justify="center"
            >
                <Row
                    justify="center"
                >
                    <Row
                        justify="center"
                        style={{
                            marginTop:'3.6rem',
                        }}
                    >
                        <img src={require('../images/star.png')} alt="star" width="25%" height="35%"/>
                    </Row>
                    <Text 
                        textWeight="600"
                        textSize={{xs:"paragraph", sm:"subheader"}}
                        style={{
                            marginTop:'3rem',
                            marginBottom:'1rem',
                        }}
                    >
                        Find us on Instagram <em>@shopskswim</em>
                    </Text>
                    <Row
                        justify="center"
                        style={{
                            marginTop:'3.6rem',
                        }}
                    >
                        <img src={require('../images/star.png')} alt="star" width="25%" height="35%"/>
                    </Row>
                    <Insta token="IGQWRPZAUpqQkIzYkZAMck00NWstWFA0Mm8tNW1aR05GTzNtWjlvX3VzeXBpd3pVUEd1UW9CZA2kwaFdqQ1NMY3hQbm5JSUdBSlUtMUdNbmJ5U2ZAKZAlcyWVVxWl81SDl5MURLZA1ZAjcEhCQ2RQSTJ1djNmSEtUMnJHZAGMZD" counter="9"/>                    
                </Row>
            </Container>
            </Col>
        </div>
    )
}

export default HomePage;