import React, { useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { ShopContext } from '../context/shopContext';
import { Text, Div, Button, Row, Col, Container, Collapse } from 'atomize';
import { Link } from 'react-router-dom';
import Loading from '../components/Loading'
import {Helmet} from 'react-helmet'

const typeParamMapping = {
    "All Swim": "swim",
    "Tops": "tops",
    "Bottoms": "bottoms",
    "Cover Ups": "coverups",
    "One Pieces": "onePieces"
}

const sizeMapping = {
    "XS": 0,
    "S": 1,
    "M": 2,
    "L": 3,
    "XL": 4
}

const ProductPage = () => {

    let { id } = useParams();
    const { resetContext, fetchProductWithId, product, addItemToCheckout, selectedSize, setSelectedSize, detailsCollapse, toggleDetailsCollapse, fitCollapse, toggleFitCollapse, returnCollapse, toggleReturnCollapse, shippingCollapse, toggleShippingCollapse, selectedImageIndex, setSelectedImageIndex, products, fetchAllProducts } = useContext(ShopContext);

    let suggestedProducts = products;
    if (product.title) {
        if (product.title.includes("Yellow")){
            suggestedProducts = products.filter((product) => (product.title.includes("Yellow")));
        } else if (product.title.includes("Emerald")) {
            suggestedProducts = products.filter((product) => (product.title.includes("Emerald")));
        } else if (product.title.includes("Cobalt")) {
            suggestedProducts = products.filter((product) => (product.title.includes("Cobalt")));
        } else if (product.title.includes("Blue")) {
            suggestedProducts = products.filter((product) => (product.title.includes("Blue")));
        } else if (product.title.includes("Lilac")) {
            suggestedProducts = products.filter((product) => (product.title.includes("Lilac")));
        } else if (product.title.includes("Magic")) {
            suggestedProducts = products.filter((product) => (product.title.includes("Magic")));
        } else if (product.title.includes("Mocha")) {
            suggestedProducts = products.filter((product) => (product.title.includes("Mocha")));
        } else if (product.title.includes("Brown")) {
            suggestedProducts = products.filter((product) => (product.title.includes("Brown")));
        } else if (product.title.includes("Bubblegum")) {
            suggestedProducts = products.filter((product) => (product.title.includes("Bubblegum")));
        } else if (product.title.includes("Satin Pink")) {
            suggestedProducts = products.filter((product) => (product.title.includes("Satin Pink")));
        } else if (product.title.includes("Magenta")) {
            suggestedProducts = products.filter((product) => (product.title.includes("Magenta")));
        } else if (product.title.includes("Ice")) {
            suggestedProducts = products.filter((product) => (product.title.includes("Ice")));
        } else if (product.title.includes("Cheetah")) {
            suggestedProducts = products.filter((product) => (product.title.includes("Cheetah")));
        } else if (product.title.includes("Groovy")) {
            suggestedProducts = products.filter((product) => (product.title.includes("Groovy")));
        } else if (product.title.includes("Trippy")) {
            suggestedProducts = products.filter((product) => (product.title.includes("Trippy")));
        } else if (product.title.includes("Paisley")) {
            suggestedProducts = products.filter((product) => (product.title.includes("Paisley")));
        } else if (product.title.includes("Hawaiian")) {
            suggestedProducts = products.filter((product) => (product.title.includes("Hawaiian")));
        } else if (product.title.includes("Peach")) {
            suggestedProducts = products.filter((product) => (product.title.includes("Peach")));
        } else if (product.title.includes("Croc")) {
            suggestedProducts = products.filter((product) => (product.title.includes("Croc")));
        } else if (product.title.includes("White")) {
            suggestedProducts = products.filter((product) => (product.title.includes("White")));
        } else if (product.title.includes("Midnight Shimmer")) {
            suggestedProducts = products.filter((product) => (product.title.includes("Midnight Shimmer")));
        } else if (product.title.includes("Punch")) {
            suggestedProducts = products.filter((product) => (product.title.includes("Punch")));
        } else if (product.title.includes("Kissed")) {
            suggestedProducts = products.filter((product) => (product.title.includes("Kissed")));
        } else if (product.title.includes("Passion")) {
            suggestedProducts = products.filter((product) => (product.title.includes("Passion")));
        } else if (product.title.includes("Seafoam")) {
            suggestedProducts = products.filter((product) => (product.title.includes("Seafoam")));
        }
        suggestedProducts = suggestedProducts.slice(0, 5);
    }

    useEffect(() => {
        fetchProductWithId(id);
        fetchAllProducts();
        resetContext();
        return () => {

        };
    }, [fetchProductWithId, id]);
    if (!product) return <Loading />


    
    return (
        <Container>
            <Helmet>
                <title>{product.title}</title>
                <meta name={`${product.title}`} content={`${product.description}`} />
            </Helmet>
            <Row>
                <Col
                    style={{
                        paddingTop:'.65rem',
                        paddingLeft: '3rem',
                        display:'flex',
                        flexDir:'column'
                    }}
                >
                    <Link
                        to={`/shop/?type=${typeParamMapping[product.productType]}`}
                    >
                        <Text
                            textColor="grey"
                            hoverTextColor="pink"
                            textSize="caption"
                        >
                            {product.productType} 
                        </Text>
                    </Link>
                    <Text
                            textColor="black"
                            textSize="caption"
                        >
                        &nbsp;&nbsp;/  {product.title}
                    </Text>
                </Col>
            </Row>
            <Row
                m={{ b: "2rem" }}
                p="2rem"
                flexDir={{ xs: 'column', md: 'row' }}
            >
                {(product.images) ? (
                    <Col
                        size="1"
                        flexDir={{ xs: 'row', md: 'column' }}
                        d={{xs:"none", md:"block"}}
                    >
                        {product.images.map((image, index) => (
                            <Div
                                key={image.id}
                                bgImg={image.src}
                                bgSize="cover"
                                bgPos="center center"
                                h="6rem"
                                style={{
                                    marginBottom: "1rem" 
                                }}
                                onClick={()=> setSelectedImageIndex(index)}
                            />
                        ))}
                    </Col>
                ): null}
                {(product.images) ? (
                        <Col>
                            <Div 
                                bgImg={product.images[selectedImageIndex].src}
                                bgSize="cover"
                                w="100%"
                                bgPos="center center"
                                h="40rem"
                            />
                        </Col>
                ): null}
                {(product.images) ? (
                    <Row
                        size="1"
                        d={{xs:"block", md:"none"}}
                        m={{t:"2rem"}}
                    >
                        <Row
                            justify="center"
                        >
                            {product.images.map((image, index) => (
                                <Div
                                    bgImg={image.src}
                                    bgSize="cover"
                                    
                                    bgPos="center center"
                                    hoverShadow="4"
                                    h="6rem"
                                    w="4rem"
                                    m={{x:".5rem", y:".25rem"}}
                                    onClick={()=> setSelectedImageIndex(index)}
                                />
                            ))}
                        </Row>
                    </Row>
                ): null}
                <Col
                    style={{marginLeft:"2rem", marginRight:"2rem"}}
                >
                    <Row
                        m={{ t:{xs: '4rem', md: '0' }}}
                        justify="center"
                    >
                        <Text
                            textWeight= '600'
                            textColor="black"
                            textSize={{xs:"title", sm:"heading", md:"display1"}}
                            style={{
                                display: 'block',
                                textAlign: 'center'
                            }}
                        >
                            {product.title}
                        </Text>
                    </Row>
                    <Row
                        justify="center"
                    >
                        {(product.images) ? (
                            <Text
                                m={{ y: '1rem' }}
                                textWeight= '500'
                                textSize={{xs:"subheader", sm:"title"}}
                                style={{
                                    display: 'block',
                                    textAlign: 'center'
                                }}
                            >
                                ${product.variants[0].price.amount}0
                            </Text>
                        ) : null}
                    </Row>
                    <Row
                        justify="center"
                        m={{t:"1rem"}}
                    >
                        <Text
                            textWeight= '600'
                            textSize="subtitle"
                            style={{
                                display: 'block',
                                textAlign: 'center'
                            }}
                        >
                            SIZE
                        </Text>
                    </Row>
                    <Row
                        m={{b:"3rem", t:"1rem"}}
                        justify="center"
                    >
                        {(selectedSize !== "XS") ? (
                            <Button
                                rounded="circle"
                                bg="white"
                                borderColor="black"
                                border="2px solid"
                                textColor="black"
                                m={{ r: '.5rem', b:".5rem" }}
                                w="3rem"
                                onClick={() => setSelectedSize("XS")}
                            >
                                XS
                            </Button>
                        ) : (
                            <Button
                                rounded="circle"
                                bg="black"
                                borderColor="black"
                                border="2px solid"
                                textColor="white"
                                m={{ r: '.5rem', b:".5rem" }}
                                w="3rem"
                                onClick={() => setSelectedSize("")}
                            >
                                XS
                            </Button>
                        )}
                        {(selectedSize !== "S") ? (
                            <Button
                                rounded="circle"
                                bg="white"
                                borderColor="black"
                                border="2px solid"
                                textColor="black"
                                m={{ r: '.5rem', b:".5rem" }}
                                w="3rem"
                                onClick={() => setSelectedSize("S")}
                            >
                                S
                            </Button>
                        ) : (
                            <Button
                                rounded="circle"
                                bg="black"
                                borderColor="black"
                                border="2px solid"
                                textColor="white"
                                m={{ r: '.5rem', b:".5rem" }}
                                w="3rem"
                                onClick={() => setSelectedSize("")}
                            >
                                S
                            </Button>
                        )}
                        {(selectedSize !== "M") ? (
                            <Button
                                rounded="circle"
                                bg="white"
                                borderColor="black"
                                border="2px solid"
                                textColor="black"
                                m={{ r: '.5rem', b:".5rem" }}
                                w="3rem"
                                onClick={() => setSelectedSize("M")}
                            >
                                M
                            </Button>
                        ) : (
                            <Button
                                rounded="circle"
                                bg="black"
                                borderColor="black"
                                border="2px solid"
                                textColor="white"
                                m={{ r: '.5rem', b:".5rem" }}
                                w="3rem"
                                onClick={() => setSelectedSize("")}
                            >
                                M
                            </Button>
                        )}
                        {(selectedSize !== "L") ? (
                            <Button
                                rounded="circle"
                                bg="white"
                                borderColor="black"
                                border="2px solid"
                                textColor="black"
                                m={{ r: '.5rem', b:".5rem" }}
                                w="3rem"
                                onClick={() => setSelectedSize("L")}
                            >
                                L
                            </Button>
                        ) : (
                            <Button
                                rounded="circle"
                                bg="black"
                                borderColor="black"
                                border="2px solid"
                                textColor="white"
                                m={{ r: '.5rem', b:".5rem" }}
                                w="3rem"
                                onClick={() => setSelectedSize("")}
                            >
                                L
                            </Button>
                        )}
                        {(selectedSize !== "XL") ? (
                            <Button
                                rounded="circle"
                                borderColor="black"
                                border="2px solid"
                                textColor="black"
                                m={{ r: '.5rem', b:".5rem" }}
                                w="3rem"
                                onClick={() => setSelectedSize("XL")}
                                bg="white"
                            >
                                XL
                            </Button>
                        ) : (
                            <Button
                                rounded="circle"
                                bg="black"
                                borderColor="black"
                                border="2px solid"
                                textColor="white"
                                m={{ r: '.5rem', b:".5rem" }}
                                w="3rem"
                                onClick={() => setSelectedSize("")}
                            >
                                XL
                            </Button>
                        )}
                    </Row>
                    <Row
                        style={{borderTop: '1px solid grey'}}
                        justify="center"
                    >
                        <Button
                            m={{x:"1rem", y:".5rem"}}
                            textWeight= '500'
                            textSize="subtitle"
                            bg="white"
                            textColor="black"
                            onClick={() => toggleDetailsCollapse()}
                            h="1rem"
                        >
                            Details
                        </Button>
                    </Row>
                    <Row>
                        <Collapse isOpen={detailsCollapse}
                            m={{x:"2rem", y:".5rem"}}
                        >
                            <Text
                                textWeight= '400'
                                textSize="caption"
                                style={{
                                    display: 'block',
                                    textAlign: 'center'
                                }}
                            >
                                {product.description}
                            </Text>
                        </Collapse>
                    </Row>
                    <Row
                        style={{borderTop: '1px solid grey'}}
                        justify="center"
                    >
                        <Button
                            m={{x:"1rem", y:".5rem"}}
                            textWeight= '500'
                            textSize="subtitle"
                            bg="white"
                            textColor="black"
                            onClick={() => toggleFitCollapse()}
                            h="1rem"
                        >
                            Fit
                        </Button>
                    </Row>
                    <Row
                        justify="center"
                    >
                        <Collapse isOpen={fitCollapse}
                            m={{x:"1rem", y:".5rem"}}
                            justify="center"
                        >
                            <Link
                                to='/sizeGuide'
                            >
                                <Text
                                    textWeight= '400'
                                    textSize="caption"
                                    p={{t:".5rem"}}
                                    textColor="#EE3F84"
                                    style={{
                                        display: 'block',
                                        textAlign: 'center'
                                    }}
                                >
                                    Size Guide
                                </Text>
                            </Link>
                        </Collapse>
                    </Row>
                    <Row
                        style={{borderTop: '1px solid grey'}}
                        justify="center"
                    >
                        <Button
                            m={{x:"1rem", y:".5rem"}}
                            textWeight= '500'
                            textSize="subtitle"
                            bg="white"
                            textColor="black"
                            onClick={() => toggleReturnCollapse()}
                            h="1rem"
                        >
                            Returns
                        </Button>
                    </Row>
                    <Row>
                        <Collapse isOpen={returnCollapse}
                            m={{x:"1rem", y:".5rem"}}
                        >
                            <Text
                                textWeight= '400'
                                textSize="caption"
                                style={{
                                    display: 'block',
                                    textAlign: 'center'
                                }}
                            >
                                SK Swim accepts unworn, unwashed suits with tags and hygiene liners attached within 30 days of delivery.
                            </Text>
                            <Link
                                to='/return'
                            >
                                <Text
                                    textWeight= '400'
                                    textSize="caption"
                                    p={{t:".5rem"}}
                                    textColor="#EE3F84"
                                    style={{
                                        display: 'block',
                                        textAlign: 'center'
                                    }}
                                >
                                    Return Policy
                                </Text>
                            </Link>
                        </Collapse>
                    </Row>
                    <Row
                        style={{borderTop: '1px solid grey'}}
                        justify="center"
                    >
                        <Button
                            m={{x:"1rem", y:".5rem"}}
                            textWeight= '500'
                            textSize="subtitle"
                            bg="white"
                            textColor="black"
                              justify="left"
                              onClick={() => toggleShippingCollapse()}
                              h="1rem"
                        >
                            Shipping
                        </Button>
                    </Row>
                    <Row>
                        <Collapse isOpen={shippingCollapse}
                            m={{x:"1rem", y:".5rem"}}
                        >
                            <Text
                                textWeight= '400'
                                textSize="caption"
                                style={{
                                    display: 'block',
                                    textAlign: 'center'
                                }}
                            >
                                We offer FREE shipping on ALL domestic orders. International order shipping is calculated at checkout and are final sale.
                            </Text>
                            <Link
                                to='/shippingPolicy'
                            >
                                <Text
                                    textWeight= '400'
                                    textSize="caption"
                                    p={{t:".5rem"}}
                                    textColor="#EE3F84"
                                    style={{
                                        display: 'block',
                                        textAlign: 'center'
                                    }}
                                >
                                    More info
                                </Text>
                            </Link>
                        </Collapse>
                    </Row>
                    <Row
                        justify="center"
                    >
                        <Button
                            w="100%"
                            rounded="circle"
                            m={{t:"1rem", b:"3rem"}}
                            onClick={() => addItemToCheckout(product.variants[sizeMapping[selectedSize]].id)}
                            disabled={selectedSize === ""}
                            bg="#EE3F84"
                        >
                            Add To Cart
                        </Button>
                    </Row>
                </Col>
            </Row>
            <Row
                justify="center"
            >
                <Row
                    justify="center"
                    style={{
                        paddingRight: '1rem',
                        marginTop:"1.75rem"
                    }}
                >
                    <img src={require('../images/star.png')} alt="star" width="10rem" height="10rem"/>
                </Row>
                <Text
                    m={{ y: '1rem' }}
                    textWeight= '500'
                    textSize="title"
                    style={{
                        fontStyle:"italic"
                    }}
                >
                    More looks to love
                </Text>
                <Row
                    justify="center"
                    style={{
                        paddingLeft: '1rem',
                        marginTop:"1.75rem"
                    }}
                >
                    <img src={require('../images/star.png')} alt="star" width="10rem" height="10rem"/>
                </Row>
            </Row>
            <Row
                justify="center"
                p={{b:"4rem"}}
            >
                <div 
                    style={{
                        display: 'flex',
                        marginTop: '1rem',
                        overflow: 'scroll'
                    }}
                >
                    {suggestedProducts.map(product => (
                        <Col 
                            key={product.id}
                            size="4"
                        >
                            <Link to={`/product/${product.id.substring(product.id.indexOf('t') + 2)}`} style={{ textDecoration: 'none' }}>
                                <Div
                                    h={{xs:"12rem", sm:"16rem", md:"20rem"}}
                                    w={{xs:"5rem", sm:"12rem", md: "15rem"}}
                                    hoverShadow="4"
                                    bgImg={product.images[0].src}
                                    bgSize="cover"
                                    bgPos="center center"
                                    m={{ b: "1.5rem"}}
                                />                                   
                            </Link>
                        </Col>
                    ))}
                </div>
            </Row>
        </Container>
    )
}

export default ProductPage;