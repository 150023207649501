import React from 'react';
import { Row, Col, Icon, Div } from 'atomize';

import {
    FooterContainer,
    FooterText,
    LinkChild,
} from './FooterElements';

const Footer = () => {
    return (
        <FooterContainer>
            <div>
                <Row
                    style={{
                        paddingBottom: '4rem',
                        display: 'flex',
                        justifyContent: 'center'
                    }}
                >
                    <a
                        href="https://www.instagram.com/shopskswim/"
                        target="_blank"
                    >
                        <Icon
                            name="Instagram"
                            size="2rem"
                            color="white"
                        />                                   
                    </a>
                    <a
                        href="https://www.facebook.com/shopskswim"
                        target="_blank"
                    >
                        <Icon
                            name="Facebook" 
                            size="2rem"
                            color="white"
                        />                                   
                    </a>
                    <a
                        href="https://www.tiktok.com/@skswim"
                        target="_blank"
                    >
                        <Div
                            m={{t:'.3rem'}}
                            h="1.5rem"
                            w="1.5rem"
                            bgImg={require('../../images/tiktok.png')}
                            bgSize="cover"
                            bgPos="center center"
                        />                                     
                    </a>
                </Row>
                <Row
                    flexDir={{ xs: 'column', lg: 'row' }}
                    align="center"
                >
                    <Col
                        w="16rem"
                    >
                        <FooterText>
                            SK SWIM
                        </FooterText>
                        <LinkChild
                            to='/about'
                        >
                            About Us
                        </LinkChild>
                        <LinkChild
                            to='/termsAndConditions'
                        >
                            Terms and Conditions
                        </LinkChild>
                        <LinkChild
                            to='/privacyPolicy'
                        >
                            Privacy Policy
                        </LinkChild>
                    </Col>
                    <Col
                        w="16rem"
                    >
                        <FooterText>
                            Customer Care
                        </FooterText>
                        <LinkChild
                            to='/shippingPolicy'
                        >
                            Shipping Policy
                        </LinkChild>
                        <LinkChild
                            to='/sizeGuide'
                        >
                            Bikini Size Guide
                        </LinkChild>
                        <LinkChild
                            to='/return'
                        >
                            Return Request
                        </LinkChild>
                        <LinkChild
                            to='/contact'
                        >
                            Contact Us
                        </LinkChild>
                        <LinkChild
                            to='/FAQ'
                        >
                            FAQ
                        </LinkChild>
                    </Col>
                    <Col
                        w="16rem"
                    >
                        {/* <FooterText>
                            Newsletter
                        </FooterText>
                        <FooterTextPlain>
                            Sign up for our newsletter and be first on sales and new releases!
                        </FooterTextPlain>
                        <NewsletterContainer>
                            <Input
                                placeholder="Email address"
                                borderColor="white"
                                w="16rem"
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                                suffix={
                                    <Button
                                        pos="absolute"
                                        onClick={() => sub(email)}
                                        bg="#EE3F84"
                                        hoverBg="grey"
                                        w="6rem"
                                        top="0"
                                        right="0"
                                    >
                                    {subscribed ? (
                                        <Text
                                            textColor="white"
                                            cursor="pointer"
                                        >
                                            Subscribed
                                        </Text>
                                    ) : (
                                        <Text
                                            textColor="white"
                                            cursor="pointer"
                                        >
                                            Sign Up
                                        </Text>
                                    )}
                                    </Button>
                                }
                            />
                        </NewsletterContainer>  */}
                        <Div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                            p={{t:{xs:"1rem", sm:"1rem", md:"0"}}}
                        >
                            <img src={require('../../images/creditCards.png')} alt="cards" width="50%"/>
                        </Div>
                    </Col>
                </Row>
            </div>
        </FooterContainer>
    )
}

export default Footer;