import React, { useEffect, useContext } from 'react';
import { Div, Container, Row, Col, Text } from 'atomize';
import { ShopContext } from '../context/shopContext';
import {Helmet} from 'react-helmet'

const SizeGuidePage = () => {
    const { resetContext } = useContext(ShopContext);
    useEffect(() => {
        resetContext();
        return () => {
        };
    }, [resetContext])
    return (
        <div>
            <Helmet>
                <title>Size Guide</title>
                <meta name="SK Swim Size Guide: Boutique Women's Swimwear" content="Check out our size guide to get the perfect fit for your new favorite swimsuit."/>
            </Helmet>
            <Container>
                <Row
                    justify="center"
                    style={{paddingTop:'1rem'}}
                >
                    <Text
                        textColor="black"
                        textWeight="700"
                        textSize={{xs: "display1", sm:"display2", md:"display3"}}
                        style={{
                            fontStyle: 'italic',
                        }}
                    >
                        s i z e
                    </Text>
                    <Row
                        justify="center"
                        m={{t:{xs:"1.25rem", sm:"1.4rem", md:"2rem"}}}
                        style={{
                            paddingLeft: '1rem',
                            paddingRight: '1rem'
                        }}
                    >
                        <img src={require('../images/star.png')} alt="star" width="25%" height="35%"/>
                    </Row>
                    <Text
                        textColor="black"
                        textWeight="700"
                        textSize={{xs: "display1", sm:"display2", md:"display3"}}
                        style={{
                            fontStyle: 'italic',
                        }}
                    >
                        g u i d e
                    </Text>
                </Row>
            </Container>
            <Div
                h="100%"
                bgImg={require('../images/sizeGuide.jpg')}
                bgPos="center center"
                bgSize="cover"
                style={{marginTop:"2rem", marginBottom:'-1.5rem', imageRendering:"smooth"}}
                justify="center"
            >
                <Container
                    p={{t:"6rem", l:{xs:"2rem", sm:"3rem", md:"7rem"}, r:{xs:"2rem", sm:"3rem", md:"7rem"}, b:"6rem"}}
                >
                    <Row
                        bg="black"
                        h="4rem"
                        p={{t:"1rem"}}
                    >
                        <Col
                            w="10%"
                        >
                        </Col>
                        <Col
                            w="30%"
                        >
                            <Text
                                textColor="white"
                                textWeight="700"
                                textSize="title"
                                style={{
                                    fontStyle: 'italic',
                                    textAlign: "center"
                                }}
                            >
                                Bust
                            </Text>
                        </Col>
                        <Col
                            w="30%"
                        >
                            <Text
                                textColor="white"
                                textWeight="700"
                                textSize="title"
                                style={{
                                    fontStyle: 'italic',
                                    textAlign: "center"
                                }}
                            >
                                Waist
                            </Text>
                        </Col>
                        <Col
                            w="30%"
                        >
                            <Text
                                textColor="white"
                                textWeight="700"
                                textSize="title"
                                style={{
                                    fontStyle: 'italic',
                                    textAlign: "center"
                                }}
                            >
                                Hips
                            </Text>
                        </Col>
                    </Row>
                    <Row
                        h="8rem"
                        justify="center"
                        bg="#FFF3F6E5"
                    >
                        <Col
                            w="20%"
                            bg="black"
                        >
                            <Text
                                textColor="white"
                                textWeight="700"
                                textSize="title"
                                style={{
                                    fontStyle: 'italic',
                                    textAlign: "center",
                                }}
                                p={{t:"3rem"}}
                            >
                                XS
                            </Text>
                        </Col>
                        <Col
                            w="30%"
                            bg="#EE3F841A"
                        >
                            <Text
                                textColor="black"
                                textWeight="500"
                                textSize="subtitle"
                                style={{
                                    textAlign: "center"
                                }}
                                p={{t:"3rem"}}
                            >
                                76-80 cm
                            </Text>
                        </Col>
                        <Col
                            w="30%"
                            bg="#EE3F841A"
                        >
                            <Text
                                textColor="black"
                                textWeight="500"
                                textSize="subtitle"
                                style={{
                                    textAlign: "center"
                                }}
                                p={{t:"3rem"}}
                            >
                                60-64 cm
                            </Text>
                        </Col>
                        <Col
                            w="30%"
                            bg="#EE3F841A"                        
                        >
                            <Text
                                textColor="black"
                                textWeight="500"
                                textSize="subtitle"
                                style={{
                                    textAlign: "center"
                                }}
                                p={{t:"3rem"}}
                            >
                                80-84 cm
                            </Text>
                        </Col>
                    </Row>                    
                    <Row
                        h="8rem"
                        justify="center"
                        bg="#FFF3F6E5"
                    >
                        <Col
                            w="20%"
                            bg="black"
                        >
                            <Text
                                textColor="white"
                                textWeight="700"
                                textSize="title"
                                style={{
                                    fontStyle: 'italic',
                                    textAlign: "center",
                                }}
                                p={{t:"3rem"}}
                            >
                                S
                            </Text>
                        </Col>
                        <Col
                            w="30%"
                            bg="#FFF3F6E5"
                        >
                            <Text
                                textColor="black"
                                textWeight="500"
                                textSize="subtitle"
                                style={{
                                    textAlign: "center"
                                }}
                                p={{t:"3rem"}}
                            >
                                80-84 cm
                            </Text>
                        </Col>
                        <Col
                            w="30%"
                            bg="#FFF3F6E5"
                        >
                            <Text
                                textColor="black"
                                textWeight="500"
                                textSize="subtitle"
                                style={{
                                    textAlign: "center"
                                }}
                                p={{t:"3rem"}}
                            >
                                64-68 cm
                            </Text>
                        </Col>
                        <Col
                            w="30%"
                            bg="#FFF3F6E5"                        
                        >
                            <Text
                                textColor="black"
                                textWeight="500"
                                textSize="subtitle"
                                style={{
                                    textAlign: "center"
                                }}
                                p={{t:"3rem"}}
                            >
                                84-88 cm
                            </Text>
                        </Col>
                    </Row>
                    <Row
                        h="8rem"
                        justify="center"
                        bg="#FFF3F6E5"
                    >
                        <Col
                            w="20%"
                            bg="black"
                        >
                            <Text
                                textColor="white"
                                textWeight="700"
                                textSize="title"
                                style={{
                                    fontStyle: 'italic',
                                    textAlign: "center",
                                }}
                                p={{t:"3rem"}}
                            >
                                M
                            </Text>
                        </Col>
                        <Col
                            w="30%"
                            bg="#EE3F841A"
                        >
                            <Text
                                textColor="black"
                                textWeight="500"
                                textSize="subtitle"
                                style={{
                                    textAlign: "center"
                                }}
                                p={{t:"3rem"}}
                            >
                                84-88 cm
                            </Text>
                        </Col>
                        <Col
                            w="30%"
                            bg="#EE3F841A"
                        >
                            <Text
                                textColor="black"
                                textWeight="500"
                                textSize="subtitle"
                                style={{
                                    textAlign: "center"
                                }}
                                p={{t:"3rem"}}
                            >
                                68-72 cm
                            </Text>
                        </Col>
                        <Col
                            w="30%"
                            bg="#EE3F841A"                        
                        >
                            <Text
                                textColor="black"
                                textWeight="500"
                                textSize="subtitle"
                                style={{
                                    textAlign: "center"
                                }}
                                p={{t:"3rem"}}
                            >
                                88-92 cm
                            </Text>
                        </Col>
                    </Row>       
                    <Row
                        h="8rem"
                        justify="center"
                        bg="#FFF3F6E5"
                    >
                        <Col
                            w="20%"
                            bg="black"
                        >
                            <Text
                                textColor="white"
                                textWeight="700"
                                textSize="title"
                                style={{
                                    fontStyle: 'italic',
                                    textAlign: "center",
                                }}
                                p={{t:"3rem"}}
                            >
                                L
                            </Text>
                        </Col>
                        <Col
                            w="30%"
                            bg="#FFF3F6E5"
                        >
                            <Text
                                textColor="black"
                                textWeight="500"
                                textSize="subtitle"
                                style={{
                                    textAlign: "center"
                                }}
                                p={{t:"3rem"}}
                            >
                                88-92 cm
                            </Text>
                        </Col>
                        <Col
                            w="30%"
                            bg="#FFF3F6E5"
                        >
                            <Text
                                textColor="black"
                                textWeight="500"
                                textSize="subtitle"
                                style={{
                                    textAlign: "center"
                                }}
                                p={{t:"3rem"}}
                            >
                                72-76 cm
                            </Text>
                        </Col>
                        <Col
                            w="30%"
                            bg="#FFF3F6E5"                        
                        >
                            <Text
                                textColor="black"
                                textWeight="500"
                                textSize="subtitle"
                                style={{
                                    textAlign: "center"
                                }}
                                p={{t:"3rem"}}
                            >
                                92-96 cm
                            </Text>
                        </Col>
                    </Row>           
                    <Row
                        h="8rem"
                        justify="center"
                        bg="#FFF3F6E5"
                    >
                        <Col
                            w="20%"
                            bg="black"
                        >
                            <Text
                                textColor="white"
                                textWeight="700"
                                textSize="title"
                                style={{
                                    fontStyle: 'italic',
                                    textAlign: "center",
                                }}
                                p={{t:"3rem"}}
                            >
                                XL
                            </Text>
                        </Col>
                        <Col
                            w="30%"
                            bg="#EE3F841A"
                        >
                            <Text
                                textColor="black"
                                textWeight="500"
                                textSize="subtitle"
                                style={{
                                    textAlign: "center"
                                }}
                                p={{t:"3rem"}}
                            >
                                92-96 cm
                            </Text>
                        </Col>
                        <Col
                            w="30%"
                            bg="#EE3F841A"
                        >
                            <Text
                                textColor="black"
                                textWeight="500"
                                textSize="subtitle"
                                style={{
                                    textAlign: "center"
                                }}
                                p={{t:"3rem"}}
                            >
                                76-80 cm
                            </Text>
                        </Col>
                        <Col
                            w="30%"
                            bg="#EE3F841A"                        
                        >
                            <Text
                                textColor="black"
                                textWeight="500"
                                textSize="subtitle"
                                style={{
                                    textAlign: "center"
                                }}
                                p={{t:"3rem"}}
                            >
                                96-100 cm
                            </Text>
                        </Col>
                    </Row>       
                </Container>
            </Div>
        </div>
    )
}

export default SizeGuidePage;