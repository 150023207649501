import { NavLink as Link } from 'react-router-dom';
import styled from 'styled-components';

export const NavExpansion = styled.nav`
    position: fixed;
    background: #fff;
    padding: 6rem 0 2rem 0;
    top: 0;
    justify-content: center;
    display: flex;
    width: 100%;
    z-index: 900;
    border-bottom: 2px solid black;
`;

export const NavLink = styled(Link)`
    color: #000;
    text-decoration: none;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    width: 100%;
    display: block;
    &:hover {
        text-decoration: underline;
    }
    position: relative;
    z-index: 900 !important;
`;

export const NavLinkChildClickable = styled(Link)`
    color: #000;
    text-decoration: none;
    padding: .5rem 0 .5rem 0; 
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    width: 100%;
    display: block;
    &:hover {
        text-decoration: underline;
    }
`;

export const NavLinkChild = styled(Link)`
    color: #000;
    text-decoration: none;
    padding: .5rem 0 .5rem 0; 
    font-size: 12px;
    text-align: center;
    width: 100%;
    display: block;
    &:hover {
        text-decoration: underline;
    }
`;

export const NavText = styled.text`
    color: #000;
    display: block;
    text-decoration: none;
    padding: .5rem 0 .5rem 0; 
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    width: 100%;
`;