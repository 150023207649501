import React, { useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom'
import { ShopContext } from '../context/shopContext';
import { Text, Div, Row, Col, Container, Icon, Anchor } from 'atomize';
import { Link } from 'react-router-dom';
import HoverDropdown from '../components/HoverDropdown'
import Loading from '../components/Loading'
import {Helmet} from 'react-helmet'
import ReactPixel from 'react-facebook-pixel';

const typeParamMapping = {
    "swim": "All Swim",
    "tops": "Tops",
    "bottoms": "Bottoms",
    "coverups": "Cover Ups",
    "onePieces": "One Pieces",
    "bestsellers": "Bestsellers",
    "snowAngels": "Snow Angels Collection",
    "backyardBaby": "Backyard Baby Collection",
    "mens": "Men's Swim",
    "paradise": "Paradise Collection"
}

const photoParamMapping = {
    "swim": require('../images/suitup.png'),
    "tops": require('../images/tops1.png'),
    "bottoms": require('../images/bottoms1.png'),
    "coverups": require('../images/coverups1.png'),
    "onePieces": require('../images/onePiece.png'),
    "bestsellers": require('../images/bestsellers.png'),
    "snowAngels": require('../images/snowAngels.png'),
    "backyardBaby": require('../images/backyard.png'),
    "mens": require('../images/mens1.png'),
    "paradise": require('../images/paradise.png'),
}

const colorMapping = {
    "blue": ["cobalt", "cotton", "midnight shimmer", "seafoam"],
    "green": ["seafoam", "emerald", "emerald", "emerald"],
    "violet": ["lilac", "lilac", "passion", "purple"],
    "black": ["magic", "magic", "magic", "magic"],
    "brown": ["mocha", "mocha", "mocha", "mocha"],
    "pink": ["cotton", "groovy", "magenta", "punch"],
    "yellow": ["groovy", "groovy", "groovy", "groovy"],
    "orange": ["groovy", "punch", "peach", "kissed"],
    "white": ["hawaiian", "hawaiian", "hawaiian", "hawaiian"],
    "red": ["hawaiian", "hawaiian", "hawaiian", "hawaiian"]
}

const ShopPage = () => {
    const { resetContext, fetchAllProducts, products, setSelectedColor, selectedColor, selectedPrint, setSelectedPrint, selectedSort, setSelectedSort, fetchBestsellers, fetchSnowAngels, fetchBackyardBaby, fetchMens, fetchParadise, bestsellers, snowAngels, backyardBaby, mens, paradise } = useContext(ShopContext);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search)
    let filteredProducts = products;
    filteredProducts.sort((a, b) => b.createdAt.localeCompare(a.createdAt));
    let photoParam = require('../images/suitup.png');
    let pageParam = "All"

    // filter type
    if (searchParams.get('type')) {
        pageParam = typeParamMapping[searchParams.get('type')];
        if (pageParam !== "All Swim") {
            if (pageParam === "Bestsellers") {
                fetchBestsellers();
                photoParam=photoParamMapping["bestsellers"]
                filteredProducts = bestsellers;
            } else if (pageParam === "Snow Angels Collection") {
                fetchSnowAngels();
                photoParam=photoParamMapping["snowAngels"]
                filteredProducts = snowAngels;
            } else if (pageParam === "Backyard Baby Collection") {
                fetchBackyardBaby();
                photoParam=photoParamMapping["backyardBaby"]
                filteredProducts = backyardBaby;
            } else if (pageParam === "Men's Swim") {
                fetchMens();
                photoParam=photoParamMapping["mens"]
                filteredProducts = mens;
            } else if (pageParam === "Paradise Collection") {
                fetchParadise();
                photoParam=photoParamMapping["paradise"]
                filteredProducts = paradise;
            } else {
                photoParam=photoParamMapping[searchParams.get('type')]
                filteredProducts = filteredProducts.filter((product) => product.productType === typeParamMapping[searchParams.get('type')]);
            }
        } else {
            filteredProducts = filteredProducts.filter((product) => product.productType === "Tops" || product.productType === "Bottoms" || product.productType === "One Pieces");
        }
    }

    // filter search
    if (searchParams.get('search')) {
        if (searchParams.get('search') === "red" || searchParams.get('search') === "orange" || searchParams.get('search') === "yellow" || searchParams.get('search') === "green" || searchParams.get('search') === "blue" || searchParams.get('search') === "violet" || searchParams.get('search') === "pink" || searchParams.get('search') === "white" || searchParams.get('search') === "brown" || searchParams.get('search') === "black") {
            filteredProducts = filteredProducts.filter((product) => product.title.toLowerCase().includes(searchParams.get('search').toLowerCase()) || product.description.toLowerCase().includes(searchParams.get('search').toLowerCase()) || product.title.toLowerCase().includes(colorMapping[searchParams.get('search').toLowerCase()][0]) || product.title.toLowerCase().includes(colorMapping[searchParams.get('search').toLowerCase()][1]) || product.title.toLowerCase().includes(colorMapping[searchParams.get('search').toLowerCase()][2]) || product.title.toLowerCase().includes(colorMapping[searchParams.get('search').toLowerCase()][3]));
        } else {
            filteredProducts = filteredProducts.filter((product) => product.title.toLowerCase().includes(searchParams.get('search').toLowerCase()) || product.description.toLowerCase().includes(searchParams.get('search').toLowerCase()));
        }
        pageParam = "'" + searchParams.get('search') + "'"
        photoParam = require('../images/skswim.jpg');
    }

    // filter color and print
    if (selectedColor !== "Color" && selectedPrint !== "Print") {
        filteredProducts = filteredProducts.filter((product) => (product.title.toLowerCase().includes(selectedPrint.toLowerCase())) && (product.title.toLowerCase().includes(selectedColor.toLowerCase()) || product.title.toLowerCase().includes(colorMapping[selectedColor.toLowerCase()][0]) || product.title.toLowerCase().includes(colorMapping[selectedColor.toLowerCase()][1]) || product.title.toLowerCase().includes(colorMapping[selectedColor.toLowerCase()][2]) || product.title.toLowerCase().includes(colorMapping[selectedColor.toLowerCase()][3])));
    } else if (selectedPrint !== "Print" && selectedColor === "Color") {
        filteredProducts = filteredProducts.filter((product) => product.title.toLowerCase().includes(selectedPrint.toLowerCase()));
    } else if (selectedColor !== "Color" && selectedPrint === "Print"){
        filteredProducts = filteredProducts.filter((product) => product.title.toLowerCase().includes(selectedColor.toLowerCase()) || product.title.toLowerCase().includes(colorMapping[selectedColor.toLowerCase()][0]) || product.title.toLowerCase().includes(colorMapping[selectedColor.toLowerCase()][1]) || product.title.toLowerCase().includes(colorMapping[selectedColor.toLowerCase()][2]) || product.title.toLowerCase().includes(colorMapping[selectedColor.toLowerCase()][3]));
    }

    if (selectedSort !== "Sort") {
        if (selectedSort === "Price: Low to High") {
            filteredProducts.sort((a, b) => parseFloat(a.variants[0].price.amount) - parseFloat(b.variants[0].price.amount));
        } else if (selectedSort === "Price: High to Low") {
            filteredProducts.sort((a, b) => parseFloat(b.variants[0].price.amount) - parseFloat(a.variants[0].price.amount));
        } else if (selectedSort === "Title: A to Z") {
            filteredProducts.sort((a, b) => a.title.localeCompare(b.title));
        } else {
            filteredProducts.sort((a, b) => b.title.localeCompare(a.title));
        }
    }
    ReactPixel.init('2052293924981104', {}, { debug: true, autoConfig: false });
    ReactPixel.trackCustom('View shop', pageParam);

    const colorList = (
        <Div p={{ x: "2rem", y: "0.5rem" }}>
            {["Color", "Red", "Orange", "Yellow", "Green", "Blue", "Violet", "Pink", "White", "Brown", "Black"].map((color) => (
            <Row
                w="100%"
                style={{
                    display:'inline-block'
                }}
                onClick={() => setSelectedColor(color)}
            >
                {(color!=="Color") ? (
                    <div>
                        <Text
                            textColor="black"
                            hoverTextColor="pink"
                            d="block"
                            style={{
                                display: "block"
                            }}
                        >
                            {color}
                        </Text>
                        <Icon
                            name="Dot"
                            size="24px"
                            color={color.toLowerCase()}
                            style={{
                                display: "block"
                            }}
                        />
                    </div>
                ) : (
                    <Text
                        textColor="black"
                        hoverTextColor="pink"
                        d="block"
                        style={{
                            display: "block",
                            paddingBottom: "1rem"
                        }}
                    >
                        {color}
                    </Text>
                )}
            </Row>
            ))}
        </Div>
    );


    const printList = (
        <Div p={{ x: "2rem", y: "0.5rem" }}>
            {["Print", "Satin", "Velvet", "Tropical", "Paisley", "Trippy", "Cheetah", "Groovy", "Hawaiian", "Shimmer"].map((pattern) => (
            <Anchor textColor="black" hoverTextColor="pink" d="block" p={{ y: "0.25rem" }} onClick={() => setSelectedPrint(pattern)}>
                {pattern}
            </Anchor>
            ))}
        </Div>
    );

    const sortList = (
        <Div p={{ x: "2rem", y: "0.5rem" }}>
            {["Sort", "Price: Low to High", "Price: High to Low", "Title: A to Z", "Title: Z to A"].map((sortType) => (
            <Anchor textColor="black" hoverTextColor="pink" d="block" p={{ y: "0.25rem" }} onClick={() => setSelectedSort(sortType)}>
                {sortType}
            </Anchor>
            ))}
        </Div>
    );

    useEffect(() => {
        fetchAllProducts();
        fetchBestsellers();
        fetchSnowAngels();
        fetchBackyardBaby();
        fetchMens();
        fetchParadise();
        resetContext();
    }, [fetchAllProducts], [fetchBestsellers], [fetchSnowAngels], [fetchBackyardBaby], [fetchMens], [fetchParadise])

    if(!products) return <Loading/>
    return (
        <div>
            <Helmet>
                <title>Shop SK Swim</title>
                <meta name="Shop SK Swim: Boutique Women's Swimwear" content="Browse the complete collection of trendy, tropical, feel-good swimwear. Perfect for standing out this summer."/>
            </Helmet>
            <Col
            >
                <Row>
                    <Col
                        display='block'
                    >
                        <Div style={{
                                top: '5rem',
                                alignSelf: 'center',
                            }}
                        >
                            <Div
                                bgImg={photoParam}
                                bgPos="center center"
                                bgSize="cover"
                                h={{xs:"12rem", sm:"22rem", md:"30rem", lg:"38rem"}}
                                style={{
                                    top: '6rem',
                                    imageRendering:"smooth"
                                }}
                                justify="center"
                                
                            />
                        </Div>
                    </Col>
                </Row>
            </Col>
            <Container
                style={{
                    marginBottom: '6rem',
                    paddingLeft:"4rem",
                    paddingRight:"4rem"
                }}
            >
                <Row
                    style={{
                        display: 'block'                        
                    }}
                    pos='relative'
                    flexDir='row'
                    w='100%'
                    h='4.5rem'
                    bg="#fff"
                >
                    <Row>
                        <Col
                            style={{
                                paddingTop:'.65rem',
                                paddingLeft: '1rem',
                                display:'flex',
                                flexDir:'column'
                            }}
                        >
                            <Link
                                to="/"
                            >
                                <Text
                                    textColor="grey"
                                    hoverTextColor="pink"
                                    textSize="caption"
                                >
                                    Home 
                                </Text>
                            </Link>
                            <Text
                                    textColor="black"
                                    textSize="caption"
                                >
                                &nbsp;&nbsp;|  Shop {pageParam}
                            </Text>
                        </Col>
                    </Row>
                    <Row
                        style={{
                            paddingTop:'.5rem'
                        }}
                    >
                        <div style={{display:'flex', position: 'absolute', color:"white"}}>
                            <Col
                                w="6rem"
                            >
                                <HoverDropdown list={colorList} title={selectedColor}/>
                            </Col>
                            <Col
                                w="6rem"
                            >
                                <HoverDropdown list={printList} title={selectedPrint}/>
                            </Col>
                        </div>
                        <div style={{display:'flex', position: 'absolute', right: '-1rem', justifyContent: 'right'}}>
                            <Col 
                                w={{xs:"6rem", sm:'13rem'}}
                            >
                                <HoverDropdown list={sortList} title={selectedSort}/>
                            </Col>
                        </div>
                    </Row>
                </Row>
                <Row>
                    {filteredProducts.map(product => (
                        <Col
                            style={{
                                marginTop: '2rem',
                                marginBottom: '2rem'
                            }}
                            key={product.id}
                            size={{xs:"6", sm:"4", md:"3"}}
                        >
                            
                                <Div>
                                    <Link
                                        to={`/product/${product.id.substring(product.id.indexOf('t') + 2)}`}
                                        style={{
                                            textDecoration: 'none'
                                        }}
                                    >
                                        {product.images[0] ? (
                                            <Div
                                                h={{xs: "14rem", sm:"18rem"}}
                                                bgImg={product.images[0].src}
                                                bgSize="cover"
                                                bgPos="center center"
                                                hoverShadow="4"
                                                transition="0.3s"
                                                m={{ b: "1rem" }}
                                            />        
                                        ): null}                           
                                        <Text                                 
                                            textWeight= '600'
                                            textColor="black"
                                        >
                                            {product.title}
                                        </Text>
                                    </Link>
                                    <Text
                                        style={{
                                            paddingTop:'.5rem'
                                        }}
                                        textSize='caption'
                                    >
                                        {"$" + String(product.variants[0].price.amount) + "0"}
                                    </Text>
                                </Div>
                        </Col>
                    ))}
                </Row>
                {(filteredProducts.length < 1) ? (
                    <Row>
                        <Col
                            style={{
                                marginTop: '12rem',
                                marginBottom: '12rem'
                            }}
                            d="flex"
                            justify="center"
                        >
                            <Text
                                textColor="grey"
                                textSize="caption"
                            >
                                No items match your search. Try a different search parameter.
                            </Text>
                        </Col>
                    </Row>
                ) : null}
            </Container>
        </div>
    )
}

export default ShopPage;