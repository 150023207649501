import React from "react";
import './App.css';
import { Provider as StyletronProvider, DebugEngine } from "styletron-react";
import { Client as Styletron } from "styletron-engine-atomic";
import ShopProvider from './context/shopContext';
import HomePage from './pages/HomePage';
import ProductPage from './pages/ProductPage';
import ShippingPolicyPage from "./pages/ShippingPolicyPage";
import TermsConditionsPage from "./pages/TermsConditionsPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import AboutPage from './pages/AboutPage';
import ShopPage from './pages/ShopPage';
import Navbar from './components/NavBar/NavBar';
import SizeGuidePage from "./pages/SizeGuidePage";
import ReturnPage from "./pages/ReturnPage";
import ContactPage from "./pages/ContactPage";
import FAQPage from "./pages/FAQPage";
import Footer from './components/Footer/Footer'
import Cart from './components/Cart';
import { HashRouter as Router, Route, Routes } from 'react-router-dom'
import ReactPixel from 'react-facebook-pixel';
import CookieConsent from "react-cookie-consent";

const debug = process.env.NODE_ENV === "production" ? void 0 : new DebugEngine();
const engine = new Styletron();

ReactPixel.init('2052293924981104', {}, { debug: true, autoConfig: false });
ReactPixel.revokeConsent();
ReactPixel.pageView();
ReactPixel.fbq('track', 'PageView');

const App = () => {
  return (
    <ShopProvider>
      <StyletronProvider
        value={engine}
        debug={debug}
        debugAfterHydration
      >
        <Router>
              <Navbar/>
              <CookieConsent
                  onAccept={() => {
                    ReactPixel.grantConsent();
                  }}
                  buttonStyle={{ backgroundColor: "white" }}
                  overlay
                  overlayClasses="overlayclass"
                  buttonText="Accept"
                  location="none"
                  color="black"
              >
                By clicking the "Accept" button, you agree to accept all cookies.
              </CookieConsent>
              <div 
                style={{
                  marginTop:'80px',
                }}
              >
                <Routes>
                  <Route
                    path='/'
                    element={<HomePage/>}
                  />
                  <Route
                    exact path='/about'
                    element={<AboutPage/>}
                  />
                  <Route
                    exact path='/shop'
                    element={<ShopPage/>}
                  />
                  <Route
                    exact path='/shippingPolicy'
                    element={<ShippingPolicyPage/>}
                  />
                  <Route
                    exact path='/termsAndConditions'
                    element={<TermsConditionsPage/>}
                  />
                  <Route
                    exact path='/privacyPolicy'
                    element={<PrivacyPolicyPage/>}
                  />
                  <Route
                    exact path='/sizeGuide'
                    element={<SizeGuidePage/>}
                  />
                  <Route
                    exact path='/return'
                    element={<ReturnPage/>}
                  />
                  <Route
                    exact path='/contact'
                    element={<ContactPage/>}
                  />
                  <Route
                    exact path='/FAQ'
                    element={<FAQPage/>}
                  />
                  <Route
                    exact path='/product/:id'
                    element={<ProductPage/>}
                  />
                </Routes>
                <Footer/>
              </div>
              <Cart/>
        </Router>
      </StyletronProvider>
    </ShopProvider>
  );
}

export default App;