import React, {useContext} from 'react'
import { Row, Col, Icon, Modal, Button, Input, Div } from 'atomize';
import { Link } from 'react-router-dom';
import { ShopContext } from '../../context/shopContext'
import {
    NavLink,
    NavExpansion,
    NavText,
    NavLinkChild,
    NavLinkChildClickable,
} from './NavBarElements';
import ReactPixel from 'react-facebook-pixel';

const Navbar = () => {
    const { openCart, isShopContainerOpen, openShopContainer, closeShopContainer, isCustomerCareOpen, openCustomerCareContainer, closeCustomerCareContainer, isSearchOpen, toggleSearchContainer, showMenuPanel, toggleMenuPanel, handleInputChange, searchParam, mobileShopClick} = useContext(ShopContext)
    ReactPixel.init('2052293924981104', {}, { debug: true, autoConfig: false });

    return (
        <div
            style={{
                position:'fixed',
                height: '5rem',
                background: '#fff',
                top: '0',
                width: '100%',
                zIndex:"400"
            }}
        >
            <div
                style={{
                    marginTop: '1rem',
                    alignItems: 'center',
                    display: 'flex',
                }}
            >
                <Col
                    d={{ xs: "none", md: "block" }}
                    w="33%"
                >
                    <Row
                        align="center"
                    >
                        <Col
                            w="5rem"
                        />
                        <Col
                            style={{
                                zIndex:'999'
                            }}
                        >
                            <NavLink
                                to='/shop'
                                onMouseLeave={() => closeShopContainer()}
                                onMouseEnter={() => openShopContainer()}
                            >
                                Shop
                            </NavLink>
                        </Col>
                        <Col
                            w="5rem"
                        />
                        <Col
                            style={{
                                zIndex:'999'
                            }}
                        >
                            <NavText onMouseEnter={() => openCustomerCareContainer()} onMouseLeave={() => closeCustomerCareContainer()}>
                                Customer Care
                            </NavText>
                        </Col>
                        <Col
                            w="5rem"
                        />
                        <Col
                            style={{
                                zIndex:'999'
                            }}
                        >
                            <NavLink to='/about'>
                                About Us
                            </NavLink>
                        </Col>
                        <Col
                            w="5rem"
                        />
                    </Row>
                </Col>
                <Col
                    d={{ xs: "flex", md: "none" }}
                    w="33%"
                >
                    <Col
                        w="12rem"
                    >
                    </Col>
                    <Col
                        w="12rem"
                        style={{                                
                            zIndex:"999",
                        }}
                    >
                        <div
                            onClick={() => toggleMenuPanel()}
                            style={{
                                cursor:'pointer',
                                zIndex:"999",
                                flexDirection:'column'
                            }}
                        >
                            <Icon
                                name="Menu"
                                size="24px"
                                color="fff"
                            />
                        </div>
                    </Col>
                    <Col
                        w="12rem"
                    />
                </Col>
                <Col
                    style={{
                        flexDirection: 'column',
                        display: 'flex',
                        zIndex:'999'
                    }}
                    m={{l:"-1rem"}}
                    w="33%"
                >
                    <Link
                        to='/'
                        style={{
                            cursor: 'pointer',
                            alignSelf:'center',
                            zIndex:'999'
                        }}
                    >
                        <img
                            src={require('../../images/logo.jpeg')}
                            alt="logo"
                            height="50rem"
                        />
                    </Link>
                </Col>
                <Col
                    w="33%"
                >
                    <Row
                        align="center"
                    >
                        <Col
                            w="12rem"
                        >
                        </Col>
                        <Col
                            w="5rem"
                            d={{xs:"none", md:"block"}}
                        />
                        <Col
                            style={{
                                zIndex:'999'
                            }}
                            w="12rem"
                        >
                            <div
                                onClick={() => toggleSearchContainer()}
                                style={{
                                    cursor:'pointer'
                                }}
                            >
                                <Icon
                                    name="Search"
                                    size="24px"
                                    color="fff"
                                />
                            </div>
                        </Col>
                        <Col
                            w="5rem"
                        />
                        <Col
                            w="12rem"
                            style={{
                                zIndex:'999'
                            }}
                        >
                            <div
                                onClick={() => openCart()}
                                style={{
                                    cursor:'pointer'
                                }}
                            >
                                <Icon
                                    name="Bag"
                                    size="24px"
                                    color="fff"
                                />
                            </div>
                        </Col>
                        <Col
                            w="5rem"
                            d={{xs:"block", md:"none"}}
                        />
                        <Col
                            w="12rem"
                        />
                    </Row>
                </Col>
            </div>
            {showMenuPanel ? (
                <Div d={{xs:"block", md: "none"}}>
                    <NavExpansion>
                        <Col>
                            <Row
                                align="center"
                            >
                                <Col
                                    style={{
                                        zIndex:'999'
                                    }}
                                >
                                    <NavLink
                                        to='/shop'
                                        onMouseLeave={() => closeShopContainer()}
                                        onMouseEnter={() => openShopContainer()}
                                    >
                                        Shop
                                    </NavLink>
                                </Col>
                                <Col
                                    style={{
                                        zIndex:'999'
                                    }}
                                >
                                    <NavLink onMouseEnter={() => openCustomerCareContainer()} onMouseLeave={() => closeCustomerCareContainer()}>
                                        Customer Care
                                    </NavLink>
                                </Col>
                                <Col
                                    style={{
                                        zIndex:'999'
                                    }}
                                >
                                    <NavLink
                                        to='/about'
                                    >
                                        About Us
                                    </NavLink>
                                </Col>
                            </Row>
                        </Col>
                    </NavExpansion>
                </Div>
            ) : null}
            {isShopContainerOpen ? (
                <NavExpansion
                    onMouseEnter={() => openShopContainer()}
                    onMouseLeave={() => closeShopContainer()}
                >
                    <Row
                        flexDir={{ xs: 'column', lg: 'row' }}
                        align="center"
                    >
                        <Col
                            w="12rem"   
                            h={{xs:"10rem", md:"9rem"}}
                        >
                            <NavText>
                                Swim
                            </NavText>
                            <NavLinkChild
                                to = "/shop?type=swim"
                                onClick={() => mobileShopClick()}
                            >
                                All Swim
                            </NavLinkChild>
                            <NavLinkChild
                                to = "/shop?type=tops"
                                onClick={() => mobileShopClick()}
                            >
                                Tops
                            </NavLinkChild>
                            <NavLinkChild
                                to = "/shop?type=bottoms"
                                onClick={() => mobileShopClick()}
                            >
                                Bottoms
                            </NavLinkChild>
                            <NavLinkChild
                                to = "/shop?type=onePieces"
                                onClick={() => mobileShopClick()}
                            >
                                One Pieces
                            </NavLinkChild>
                        </Col>
                        <Col
                            w="12rem"   
                            h="8rem"
                        >
                            <NavText>
                                Cover Ups
                            </NavText>
                            <NavLinkChild
                                to = "/shop?type=coverups"
                                onClick={() => mobileShopClick()}
                            >
                                All Cover Ups
                            </NavLinkChild>
                        </Col>
                        <Col
                            w="12rem"   
                            h="8rem"
                        >
                            <NavText>
                                Men's Swim
                            </NavText>
                            <NavLinkChild
                                to = "/shop?type=mens"
                                onClick={() => mobileShopClick()}
                            >
                                All Men's
                            </NavLinkChild>
                        </Col>
                        <Col
                            w="12rem"   
                            h="8rem" 
                        >
                            <NavText>
                                Collections
                            </NavText>
                            <NavLinkChild
                                to = "/shop?type=paradise"
                                onClick={() => mobileShopClick()}
                            >
                                Paradise
                            </NavLinkChild>
                            <NavLinkChild
                                to = "/shop?type=backyardBaby"
                                onClick={() => mobileShopClick()}
                            >
                                Backyard Baby
                            </NavLinkChild>
                            <NavLinkChild
                                to = "/shop?type=snowAngels"
                                onClick={() => mobileShopClick()}
                            >
                                Snow Angels
                            </NavLinkChild>
                        </Col>
                        <Col
                            w="12rem"   
                            h="8rem" 
                        >
                            <NavLinkChildClickable
                                to = "/shop?type=bestsellers"
                                onClick={() => mobileShopClick()}
                            >
                                Best Sellers
                            </NavLinkChildClickable>
                        </Col>
                    </Row>
                </NavExpansion>
            ) : null}
            {isCustomerCareOpen ? (
                <NavExpansion
                    onMouseEnter={() => openCustomerCareContainer()}
                    onMouseLeave={() => closeCustomerCareContainer()}
                    d={{xs:"block", md: "none"}}
                >
                    <div>
                        <Row
                            flexDir={{ xs: 'column', lg: 'row' }}
                            align="center"
                        >
                            <Col
                                w="12rem"   
                                h="6rem" 
                            >
                                <NavText>
                                    Shipping and Orders
                                </NavText>
                                <NavLinkChild
                                    to='/shippingPolicy'
                                    onClick={()=> closeCustomerCareContainer()}
                                >
                                    Shipping Policy
                                </NavLinkChild>
                            </Col>
                            <Col
                                w="12rem"
                                h="6rem"
                            >
                                <NavText>
                                    Find Your Fit
                                </NavText>
                                <NavLinkChild
                                    to='/sizeGuide'
                                    onClick={()=> closeCustomerCareContainer()}
                                >
                                    Bikini Size Guide 
                                </NavLinkChild>
                            </Col>
                            <Col
                                w="12rem"
                                h="6rem"
                            >
                                <NavText>
                                    Returns and Exchanges
                                </NavText>
                                <NavLinkChild
                                    to='/return'
                                    onClick={()=> closeCustomerCareContainer()}
                                >
                                    Return Request
                                </NavLinkChild>
                            </Col>
                            <Col
                                w="12rem"
                                h="6rem"
                            >
                                <NavText>
                                    Questions?
                                </NavText>
                                <NavLinkChild
                                    to='/contact'
                                    onClick={()=> closeCustomerCareContainer()}
                                >
                                    Contact Us
                                </NavLinkChild>
                                <NavLinkChild
                                    to='/FAQ'
                                    onClick={()=> closeCustomerCareContainer()}
                                >
                                    FAQ
                                </NavLinkChild>
                            </Col>
                        </Row>
                    </div>
                </NavExpansion>
            ) : null}
            <Modal
                isOpen={isSearchOpen}
                onClose={toggleSearchContainer}
                rounded="md"
                maxW="32rem"
                minW="23rem"
            >
                <Div
                    style={{
                        width:'100%'
                    }}
                >
                    <Input
                        placeholder="Search"
                        borderColor="black"
                        w="26rem"
                        onChange={(e) => handleInputChange(e.target.value)}
                        suffix={
                            <Link
                                to={'/shop?search=' + searchParam}
                                onClick={() => ReactPixel.trackCustom('Search', searchParam)}
                            >
                                <Button
                                    pos="absolute"
                                    bg="black"
                                    hoverBg="#EE3F84"
                                    w="3rem"
                                    top="0"
                                    right="0"
                                    onClick={() => toggleSearchContainer()}
                                >
                                    <Icon
                                        name="Search"
                                        size="20px"
                                        color="white"
                                    />
                                </Button>
                            </Link>
                        }
                    />
                </Div>
            </Modal>
        </div> 
    )
}

export default Navbar;