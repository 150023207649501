import { NavLink as Link } from 'react-router-dom';


import styled from 'styled-components';

export const FooterContainer = styled.nav`
    position: relative;
    background: #000;
    padding: 5rem 0rem 4rem 0rem; 
    justify-content: center;
    width: 100%;
    display: flex;
    z-index: 98;
`;

export const LinkChild = styled(Link)`
    color: #fff;
    text-decoration: none;
    padding: .5rem 1rem .5rem 1rem; 
    font-size: 12px;
    text-align: center;
    display: block;
    width: 100%
    &:hover {
        text-decoration: underline;
    }
`;

export const FooterText = styled.text`
    color: #fff;
    text-decoration: none;
    padding: .5rem 0 .5rem 0; 
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    display: block;
    width: 100%
`;

export const FooterTextPlain = styled.text`
    color: #fff;
    text-decoration: none;
    padding: .5rem 1rem .5rem 1rem; 
    font-size: 12px;
    text-align: center;
    display: block;
    width: 100%
`;

export const NewsletterContainer = styled.nav`
    padding-left: 1rem;
    text-align: center;
    display: block;
    width: 100%
`;