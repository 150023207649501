import React, { Component } from 'react';
import Client from 'shopify-buy';
import ReactPixel from 'react-facebook-pixel';

const ShopContext = React.createContext();

const client = Client.buildClient({
    storefrontAccessToken: "3ae8d3f6ece5da26130b543d29c876e9",
    domain: "shop-skswim.myshopify.com",
});

ReactPixel.init('2052293924981104', {}, { debug: true, autoConfig: false });

class ShopProvider extends Component {
    state = {
        products: [],
        bestsellers: [],
        snowAngels: [],
        backyardBaby: [],
        mens: [],
        paradise: [],
        product: {},
        checkout: {},
        isCartOpen: false,
        isShopContainerOpen: false,
        isCustomerCareOpen: false,
        isSearchOpen: false,
        showMenuPanel: false,
        searchParam: null,
        selectedColor: "Color",
        selectedPrint: "Print",
        selectedSort: "Sort",
        selectedSize: "",
        detailsCollapse: false,
        fitCollapse: false,
        returnCollapse: false,
        shippingCollapse: false,
        selectedImageIndex: 0
    }

    componentDidMount() {
        if (localStorage.checkout) {
            this.fetchCheckout(localStorage.checkout);
        } else {
            this.createCheckout();
        }
    }

    resetContext = async () => {
        this.setState({
            showMenuPanel: false,
            searchParam: null,
            selectedColor: "Color",
            selectedPrint: "Print",
            selectedSort: "Sort",
            selectedSize: "",
            detailsCollapse: false,
            fitCollapse: false,
            returnCollapse: false,
            shippingCollapse: false,
            selectedImageIndex: 0
        })
    }

    createCheckout = async () => {
        const checkout = await client.checkout.create();
        localStorage.setItem("checkout", checkout.id)
        await this.setState({ checkout: checkout });
    };

    fetchCheckout = async (checkoutId) => {
        client.checkout
            .fetch(checkoutId)
            .then((checkout) => {
            this.setState({ checkout: checkout });
            })
            .catch((err) => console.log(err));
    };

    addItemToCheckout = async (variantId) => {
        const lineItemsToAdd = [
          {
            variantId,
            quantity: 1
          },
        ];   
        try{
            ReactPixel.trackCustom('Add to cart', variantId);
            const checkout = await client.checkout.addLineItems(
            this.state.checkout.id,
            lineItemsToAdd
            );
            this.setState({ checkout: checkout })
        } catch(err){
        console.log(err);
      }
        this.openCart();
    };

    removeItemFromCheckout = async (variantId) => { 
        const checkoutId = this.state.checkout.id;
        try{
            const checkout = await client.checkout.removeLineItems(
                checkoutId,
                variantId
            );
            this.setState({ checkout: checkout })
        } catch(err){
            console.log(err);
        }        
    }

    fetchAllProducts = async () => {
        const products = await client.product.fetchAll(250);
        this.setState({ products: products });
    };

    fetchBestsellers = async () => {
        const bestsellers = await client.collection.fetchWithProducts('gid://shopify/Collection/427518132542', {productsFirst: 50});
        this.setState({ bestsellers: bestsellers.products });
    }

    fetchSnowAngels = async () => {
        const snowAngels = await client.collection.fetchWithProducts('gid://shopify/Collection/429825392958', {productsFirst: 50});
        this.setState({ snowAngels: snowAngels.products });
    }

    fetchBackyardBaby = async () => {
        const backyardBaby = await client.collection.fetchWithProducts('gid://shopify/Collection/439605625150', {productsFirst: 50});
        this.setState({ backyardBaby: backyardBaby.products });
    }

    fetchMens = async () => {
        //change collection number
        const mens = await client.collection.fetchWithProducts('gid://shopify/Collection/449547796798', {productsFirst: 50});
        this.setState({ mens: mens.products });
    }

    fetchParadise = async () => {
        //change collection number
        const paradise = await client.collection.fetchWithProducts('gid://shopify/Collection/449535148350', {productsFirst: 50});
        this.setState({ paradise: paradise.products });
    }

    fetchProductWithId = async (id) => {
        const stringifyId = "gid://shopify/Product/" + id + "";
        client.product.fetch(stringifyId).then((product) => {
            this.setState({ product: product });
          });
    };

    closeCart = async () => {
        this.setState({ isCartOpen: false});
    }

    openCart = async () => {
        this.setState({ isCartOpen: true});
    }

    openShopContainer = async () => {
        this.setState({ isShopContainerOpen: true});
    }

    closeShopContainer = async () => {
        this.setState({ isShopContainerOpen: false});
    }

    mobileShopClick = async () => {
        this.setState({ 
            isShopContainerOpen: false,
            showMenuPanel: false
        });
    }
    openCustomerCareContainer = async () => {
        this.setState({ isCustomerCareOpen: true});
    }

    closeCustomerCareContainer = async () => {
        this.setState({ isCustomerCareOpen: false});
    }

    toggleSearchContainer = async () => {
        this.setState({ isSearchOpen: !this.state.isSearchOpen});
    }

    toggleMenuPanel = async () => {
        this.setState({ showMenuPanel: !this.state.showMenuPanel});
    }

    handleInputChange = async (input) => {
        this.setState({searchParam: input.toLowerCase()})
    }

    setSelectedColor = async (input) => {
        this.setState({selectedColor: input})
    }

    setSelectedPrint = async (input) => {
        this.setState({selectedPrint: input})
    }

    setSelectedSort = async (input) => {
        this.setState({selectedSort: input})
    }

    setSelectedSize = async (size) => {
        this.setState({selectedSize: size})
    }

    toggleDetailsCollapse = async () => {
        this.setState({detailsCollapse: !this.state.detailsCollapse})
    }

    toggleFitCollapse = async () => {
        this.setState({fitCollapse: !this.state.fitCollapse})
    }

    toggleShippingCollapse = async () => {
        this.setState({shippingCollapse: !this.state.shippingCollapse})
    }

    toggleReturnCollapse = async () => {
        this.setState({returnCollapse: !this.state.returnCollapse})
    }

    setSelectedImageIndex = async (index) => {
        this.setState({selectedImageIndex: index})
    }

    closeNavExpansion = async () => {
        this.setState({showMenuPanel: false});
    }

    render() {
        return (
        <ShopContext.Provider
            value={{
                ...this.state,
                fetchAllProducts: this.fetchAllProducts,
                fetchProductWithId: this.fetchProductWithId,
                closeCart: this.closeCart,
                openCart: this.openCart,
                openShopContainer: this.openShopContainer,
                closeShopContainer: this.closeShopContainer,
                mobileShopClick: this.mobileShopClick,
                openCustomerCareContainer: this.openCustomerCareContainer,
                closeCustomerCareContainer: this.closeCustomerCareContainer,
                addItemToCheckout: this.addItemToCheckout,
                toggleSearchContainer: this.toggleSearchContainer,
                toggleMenuPanel: this.toggleMenuPanel,
                handleInputChange: this.handleInputChange,
                setSelectedColor: this.setSelectedColor,
                setSelectedPrint: this.setSelectedPrint,
                setSelectedSort: this.setSelectedSort,
                setSelectedSize: this.setSelectedSize,
                toggleDetailsCollapse: this.toggleDetailsCollapse,
                toggleFitCollapse: this.toggleFitCollapse,
                toggleShippingCollapse: this.toggleShippingCollapse,
                toggleReturnCollapse: this.toggleReturnCollapse,
                setSelectedImageIndex: this.setSelectedImageIndex,
                resetContext: this.resetContext,
                removeItemFromCheckout: this.removeItemFromCheckout,
                closeNavExpansion: this.closeNavExpansion,
                fetchBestsellers: this.fetchBestsellers,
                fetchSnowAngels: this.fetchSnowAngels,
                fetchBackyardBaby: this.fetchBackyardBaby,
                fetchMens: this.fetchMens,
                fetchParadise: this.fetchParadise
            }}
        >
            {this.props.children}
        </ShopContext.Provider>
        );
    }
}

const ShopConsumer = ShopContext.Consumer;

export { ShopConsumer, ShopContext };

export default ShopProvider;